/* global $, MatgenGlobal */

import * as MatgenForms from '../forms';

class AnswerForm {
  constructor({question_id = null, answer = null} = {}) {
    const inputs = [
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Answer Text',
            id: 'inputText',
            required: true,
            value: (answer && answer.text) ? answer.text : '',
          },
        },
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Subtext',
            id: 'inputSubtext',
            value: (answer && answer.sub_text) ? answer.sub_text : '',
          },
        },
        {
          component: 'Text',
          options: {
            type: 'number',
            label: 'Sort Order',
            id: 'inputSortOrder',
          },
        },
        
        {
          component: 'RawHTML',
          html: `
            <div id="icon-preview">
              <div id="answer-icon"><i class="chosen-icon fa-${MatgenGlobal.ChosenIcon.prefix} fa-${MatgenGlobal.ChosenIcon.name} fa-2xl"></i></div>
              <button id="toggle-icon-chooser" type="button" class="btn btn-secondary">Choose Icon</button>
            </div>
          `,
        },
        {
          component: 'RawHTML',
          html: `
            <input type="hidden" id="q-question-id" name="q-question-id" value="${question_id ? question_id : answer.question_id ? answer.question_id : ''}"/>
            `,
        },
      ];

    return new MatgenForms.DynamicForm(
        inputs,
        [],
        'create-answer-form'
      );
  }
}
export { AnswerForm };

// questionnaire form xxx
// answer page 
// edit answers