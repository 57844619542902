/* global $, MatgenGlobal */

import { v4 as UUID } from 'uuid';
import { MatgenEditor } from '../../../core/matgen-editor.js';

let tfile, pfile, ttfile;

$(document).on('input', '.pwcheck', e => {
  const pw = $(e.target).val();
  let valid = true;
  if (pw.length < 8) {
    valid = false;
  }
  if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Z])(?=.*[!@#$%^&*])/.test(pw)) {
    valid = false;
  }

  if (!valid) e.target.setCustomValidity('Passwords must meet requirements');
  else e.target.setCustomValidity('');
});

$(document).on('input', '[data-equal-id]', e => {
  const to_confirm = $(e.target);
  const to_equal = $(`#${to_confirm.data('equalId')}`);

  if (to_confirm.val() != to_equal.val())
    e.target.setCustomValidity('Passwords must match');
  else e.target.setCustomValidity('');
});

$(document).on('change', '#inputPreviewType', () => {
  pfile = false;
  if ($('#inputPreviewType').val() === 'LINK') {
    $('#inputPreviewLink')
      .attr('required', true)
      .closest('.row')
      .show();
  } else {
    $('#inputPreviewLink')
      .removeAttr('required')
      .closest('.row')
      .hide();
  }
  if ($('#inputPreviewType').val() === 'IMAGE') {
    $(`
        <div class="row align-items-baseline">
          <label for="inputPreviewUpload" class="col-sm-4 col-form-label">Preview Image</label>
          <div class="col-sm-8">
            <input  onkeydown="event.preventDefault()" type="text" id="inputPreviewUpload" name="inputPreviewUpload" class="form-control top" placeholder="Preview Image" value="${
              document.getElementById('file-input') &&
              document.getElementById('file-input').files[0]
                ? document.getElementById('file-input').files[0].name
                : ''
            }" required>
          </div>
        </div>
      `).insertAfter($($('#inputPreviewType').closest('.row')));
    MatgenGlobal.UI.upload(() => {
      if (document.getElementById('file-input')) {
        pfile = document.getElementById('file-input').files[0];
        $('#inputPreviewUpload').val(
          document.getElementById('file-input').files[0].name
        );
        document
          .querySelector('body')
          .removeChild(document.getElementById('file-input'));
      }
    });
  } else {
    $('#inputPreviewUpload')
      .closest('.row')
      .remove();
  }
});

$(document).on('click', '#inputTemplateFileUpload', () => {
  MatgenGlobal.UI.upload(() => {
    if (document.getElementById('template-file')) {
      tfile = document.getElementById('template-file').files[0];
      $('#inputTemplateFileUpload').val(
        document.getElementById('template-file').files[0].name
      );
      document
        .querySelector('body')
        .removeChild(document.getElementById('template-file'));
    }
  }, 'template-file');
});

$(document).on('click', '#inputTemplateThumbnailUpload', () => {
  MatgenGlobal.UI.upload(() => {
    if (document.getElementById('template-thumbnail')) {
      ttfile = document.getElementById('template-thumbnail').files[0];
      $('#inputTemplateThumbnailUpload').val(
        document.getElementById('template-thumbnail').files[0].name
      );
      document
        .querySelector('body')
        .removeChild(document.getElementById('template-thumbnail'));
    }
  }, 'template-thumbnail');
});

$(document).on('change', '#inputTemplateType', () => {
  tfile = false;
  if (['PDF', 'IMAGE'].includes($('#inputTemplateType').val())) {
    $('#inputWidth').attr('required', true);
    $('#inputHeight').attr('required', true);
    $('#inputWidth')
      .closest('.row')
      .show();
    $('#inputHeight')
      .closest('.row')
      .show();
    $('#inputPreviewType')
      .removeAttr('required')
      .closest('.row')
      .hide();
  } else {
    $('#inputWidth').removeAttr('required');
    $('#inputHeight').removeAttr('required');
    $('#inputWidth')
      .closest('.row')
      .hide();
    $('#inputHeight')
      .closest('.row')
      .hide();
  }
  if ($('#inputTemplateType').val() === 'PDF') {
    $('#inputWidth').val(2550);
    $('#inputHeight').val(3300);
  }
  if ($('#inputTemplateType').val() === 'IMAGE') {
    $('#inputWidth').val(1200);
    $('#inputHeight').val(1200);
  }
  if ($('#inputTemplateType').val() === 'FILE') {
    $('#inputPreviewType')
      .attr('required', true)
      .closest('.row')
      .show();

    $(`
            <div class="row align-items-baseline">
              <label for="inputTemplateFileUpload" class="col-sm-4 col-form-label">Template File</label>
              <div class="col-sm-8">
                <input  onkeydown="event.preventDefault()" type="text" id="inputTemplateFileUpload" name="inputTemplateFileUpload" class="form-control top" placeholder="Template File" value="${
                  document.getElementById('template-file') &&
                  document.getElementById('template-file').files[0]
                    ? document.getElementById('template-file').files[0].name
                    : ''
                }" required>
              </div>
            </div>
          `).insertAfter($($('#inputTemplateType').closest('.row')));

    $(`
          <div class="row align-items-baseline">
            <label for="inputTemplateThumbnailUpload" class="col-sm-4 col-form-label">Template Thumbnail</label>
            <div class="col-sm-8">
              <input  onkeydown="event.preventDefault()" type="text" id="inputTemplateThumbnailUpload" name="inputTemplatethumbnailUpload" class="form-control top" placeholder="Template Thumbnail" value="${
                document.getElementById('template-thumbnail') &&
                document.getElementById('template-thumbnail').files[0]
                  ? document.getElementById('template-thumbnail').files[0].name
                  : ''
              }" required>
            </div>
          </div>
        `).insertAfter($($('#inputTemplateFileUpload').closest('.row')));

    MatgenGlobal.UI.upload(() => {
      if (document.getElementById('template-file')) {
        tfile = document.getElementById('template-file').files[0];
        $('#inputTemplateFileUpload').val(
          document.getElementById('template-file').files[0].name
        );
        document
          .querySelector('body')
          .removeChild(document.getElementById('template-file'));
      }
    }, 'template-file');
  } else {
    $('#inputTemplateFileUpload')
      .closest('.row')
      .remove();

    $('#inputTemplateThumbnailUpload')
      .closest('.row')
      .remove();
  }
});
$(document).on('submit', '#forgot-password-confirm-form', async e => {
  e.preventDefault();
  const response = await MatgenGlobal.MatgenPageLoader.start({
    message: 'Resetting password',
    promise: MatgenGlobal.AuthUser.forgotPasswordSubmit(
      $('#inputEmail').val(),
      $('#inputConfirmationCode').val(),
      $('#inputPassword').val()
    ),
  });
  if (response === true) {
    MatgenGlobal.Router.goTo('/unauth');
  } else {
    $('#new-password-form .form-error')
      .text(response.message)
      .show();
  }
});

$(document).on('click', '#forgot-password', e => {
  e.preventDefault();
  MatgenGlobal.Router.goTo('/forgot-password');
});

$(document).on('click', '#cancel', e => {
  e.preventDefault();
  MatgenGlobal.Router.goTo('/unauth');
});

$(document).on('click', '#have-code', e => {
  e.preventDefault();
  MatgenGlobal.Router.goTo('/forgot-password-confirm');
});

$(document).off('click', '#template-form-submit');
$(document).on('click', '#template-form-submit', () => {
  $('#template-form').submit();
});

$(document).off('submit', '#template-form');
$(document).on('submit', '#template-form', async e => {
  e.preventDefault();
  if ($('#template-form')[0].checkValidity()) {
    const id = $('#inputTemplateID').val();
    const name = $('#inputTemplateName').val();
    const subtitle = $('#inputTemplateSubtitle').val();
    const uploads = $('#inputTemplateUploads').is(':checked') ? 1 : 0;
    const type = $('#inputTemplateType').val();
    const preview_type = $('#inputPreviewType').val();
    const preview_link = $('#inputPreviewLink').val();
    const tenant_id = $('#inputTenantID').val();
    const width = $('#inputWidth').val();
    const height = $('#inputHeight').val();
    const fonts = $('#inputFonts').val();
    const instructions = $('#inputInstructions').val();
    const text_length = $('#inputTextLength').val();

    let file_ext, preview_image_ext, thumbnail_image_ext;

    if (tfile) {
      file_ext = tfile.name.substr(
        tfile.name.lastIndexOf('.'),
        tfile.name.length
      );
    }
    if (pfile) {
      preview_image_ext = pfile.name.substr(
        pfile.name.lastIndexOf('.'),
        pfile.name.length
      );
    }

    if (ttfile) {
      thumbnail_image_ext = ttfile.name.substr(
        ttfile.name.lastIndexOf('.'),
        ttfile.name.length
      );
    }

    const payload = Object.entries({
      id,
      name,
      type,
      preview_type,
      preview_link,
      file_ext,
      preview_image_ext,
      thumbnail_image_ext,
      tenant_id,
      width,
      height,
      fonts,
      instructions,
      subtitle,
      uploads,
      text_length,
    }).reduce(
      (a, [k, v]) =>
        v === '' || typeof v === 'undefined' ? a : ((a[k] = v), a),
      {}
    );

    if (payload.fonts) {
      payload.fonts = JSON.parse(payload.fonts);
    }
    let newTemplate = false;
    if (!payload.id) {
      payload.id = UUID();
      newTemplate = true;
    }
    $('#template-form-modal').modal('hide');
    const template_response = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Saving template data',
      promise: MatgenGlobal.Data.saveTemplate(payload, !newTemplate),
    });

    if (template_response === false) {
      MatgenGlobal.UI.handleError(
        'Server Error',
        'There was a problem saving the template data.'
      );
      return false;
    }

    if (type === 'IMAGE' || type === 'PDF') {
      if (newTemplate) {
        const pageId = UUID();
        const page_response = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving page data',
          promise: MatgenGlobal.Data.savePage({
            id: pageId,
            template_id: payload.id,
            number: 0,
          }),
        });

        if (page_response === false) {
          MatgenGlobal.UI.handleError(
            'Server Error',
            'There was a problem saving the page data.'
          );
          return false;
        }
        MatgenGlobal.editor = new MatgenEditor({
          id: payload.id,
          pageId: pageId,
          fonts: payload.fonts ? payload.fonts : null,
          containerId: 'matgen-edit-canvas',
          templateId: payload.id,
        });

        MatgenGlobal.editor.width = payload.width;
        MatgenGlobal.editor.height = payload.height;

        const editDiv = $(
          `<div id="matgen-edit-canvas" class="matgen-hidden-canvas"/>`
        );
        $('body').append(editDiv);

        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Initializing editor',
          promise: MatgenGlobal.editor.init('matgen-edit-canvas'),
        });
        const pageObj = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading page object',
          promise: MatgenGlobal.UI.getPageObject(),
        });
        const page_file_response = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving page JSON',
          promise: MatgenGlobal.Data.savePageFile(
            pageId,
            pageObj,
            payload.tenant_id
          ),
        });

        if (page_file_response === false) {
          MatgenGlobal.UI.handleError(
            'Server Error',
            'There was a problem saving the page JSON file.'
          );
          return false;
        }
        if (MatgenGlobal.sidebar) {
          window.setTimeout(() => {
            MatgenGlobal.sidebar.markTemplateClean();
          }, 350);
        }

        const preview_response = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving page p[review',
          promise: MatgenGlobal.UI.savePagePreview(false, payload.tenant_id),
        });

        if (preview_response === false) {
          MatgenGlobal.UI.handleError(
            'Server Error',
            'There was a problem saving the template preview image file.'
          );
          return false;
        }
        editDiv.remove();
        delete MatgenGlobal.editor;
        MatgenGlobal.Router.goTo(`templates/${payload.id}`);
      } else {
        const row = $('#data-table')
          .DataTable()
          .row($(`td:contains(${payload.id})`).closest('tr'))
          .data();
        payload.tenant_name = row.tenant_name;
        $('#data-table')
          .DataTable()
          .row($(`td:contains(${payload.id})`).closest('tr'))
          .data(payload);
      }
    } else if (type === 'FILE') {
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving template file',
          promise: MatgenGlobal.Data.saveTemplateFile(
            `${payload.id}${payload.file_ext}`,
            tenant_id,
            tfile
          ),
        });
      } catch (e) {
        console.error(e);
      }
      if (pfile) {
        try {
          await MatgenGlobal.MatgenPageLoader.start({
            message: 'Saving template preview',
            promise: MatgenGlobal.MatgenPageLoader.start({
              message: 'Saving template preview',
              promise: MatgenGlobal.Data.saveTemplateFile(
                `${payload.id}${payload.preview_image_ext}`,
                tenant_id,
                pfile
              ),
            }),
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
    tfile = false;
    pfile = false;
    if (type === 'FILE') {
      MatgenGlobal.Router.goTo(`tags/${payload.id}`);
    }
  } else {
    $('#template-form')[0].reportValidity();
    window.setTimeout(() => {
      if (
        $('#inputPreviewUpload').length > 0 &&
        !$('#inputPreviewUpload').val()
      ) {
        MatgenGlobal.UI.upload(() => {
          if (document.getElementById('file-input')) {
            pfile = document.getElementById('file-input').files[0];
            $('#inputPreviewUpload').val(
              document.getElementById('file-input').files[0].name
            );
            document
              .querySelector('body')
              .removeChild(document.getElementById('file-input'));
          }
        });
      }
      if (
        $('#inputTemplateFileUpload').length > 0 &&
        !$('#inputTemplateFileUpload').val()
      ) {
        MatgenGlobal.UI.upload(() => {
          if (document.getElementById('template-file')) {
            tfile = document.getElementById('template-file').files[0];
            $('#inputTemplateFileUpload').val(
              document.getElementById('template-file').files[0].name
            );
            document
              .querySelector('body')
              .removeChild(document.getElementById('template-file'));
          }
        }, 'template-file');
      }
    }, 750);
  }
});

MatgenGlobal.CustomTemplateActions = item => {
  const tested = parseInt(item.tested);
  return [
    {
      action: 'mark-tested',
      title: tested === 1 ? 'Tested' : 'Untested',
      icon: tested === 1 ? 'fas fa-vial' : 'far fa-vial',
      color: tested === 1 ? 'primary' : 'warning',
    },
  ];
};

$(document).on('click', '.template-link', e => {
  e.preventDefault();
  MatgenGlobal.Router.goTo(`templates/${$(e.target).data('id')}`);
});

$(document).on('submit', '#forgot-password-confirm-form', async e => {
  e.preventDefault();
  const response = await MatgenGlobal.MatgenPageLoader.start({
    message: 'Resetting password',
    promise: MatgenGlobal.AuthUser.forgotPasswordSubmit(
      $('#inputEmail').val(),
      $('#inputConfirmationCode').val(),
      $('#inputPassword').val()
    ),
  });
  if (response === true) {
    MatgenGlobal.Router.goTo('/unauth');
  } else {
    $('#new-password-form .form-error')
      .text(response.message)
      .show();
  }
});

$(document).on('click', '#forgot-password', e => {
  e.preventDefault();
  MatgenGlobal.Router.goTo('/forgot-password');
});

$(document).on('click', '#cancel', e => {
  e.preventDefault();
  MatgenGlobal.Router.goTo('/unauth');
});

$(document).on('click', '#have-code', e => {
  e.preventDefault();
  MatgenGlobal.Router.goTo('/forgot-password-confirm');
});

/*
$(document).on('click', '#template-form-submit', () => {
  $('#template-form').submit();
});

$(document).on('submit', '#template-form', async e => {
  e.preventDefault();
  if ($('#template-form')[0].checkValidity()) {
    const id = $('#inputTemplateID').val();
    const name = $('#inputTemplateName').val();
    const subtitle = $('#inputTemplateSubtitle').val();
    const uploads = $('#inputTemplateUploads').is(':checked') ? 1 : 0;
    const type = $('#inputTemplateType').val();
    const preview_type = $('#inputPreviewType').val();
    const preview_link = $('#inputPreviewLink').val();
    const tenant_id = $('#inputTenantID').val();
    const width = $('#inputWidth').val();
    const height = $('#inputHeight').val();
    const fonts = $('#inputFonts').val();
    const instructions = $('#inputInstructions').val();

    let file_ext, preview_image_ext, thumbnail_image_ext;

    if (tfile) {
      file_ext = tfile.name.substr(
        tfile.name.lastIndexOf('.'),
        tfile.name.length
      );
    }
    if (pfile) {
      preview_image_ext = pfile.name.substr(
        pfile.name.lastIndexOf('.'),
        pfile.name.length
      );
    }

    if (ttfile) {
      thumbnail_image_ext = ttfile.name.substr(
        ttfile.name.lastIndexOf('.'),
        ttfile.name.length
      );
    }

    const payload = Object.entries({
      id,
      name,
      type,
      preview_type,
      preview_link,
      file_ext,
      preview_image_ext,
      thumbnail_image_ext,
      tenant_id,
      width,
      height,
      fonts,
      instructions,
      subtitle,
      uploads,
    }).reduce(
      (a, [k, v]) =>
        v === '' || typeof v === 'undefined' ? a : ((a[k] = v), a),
      {}
    );

    if (payload.fonts) {
      payload.fonts = JSON.parse(payload.fonts);
    }
    let newTemplate = false;
    if (!payload.id) {
      payload.id = UUID();
      newTemplate = true;
    }

    $('#template-form-modal').modal('hide');

    const template_response = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Saving template data',
      promise: MatgenGlobal.Data.saveTemplate(payload, !newTemplate),
    });

    if (template_response === false) {
      MatgenGlobal.UI.handleError(
        'Server Error',
        'There was a problem saving the template data.'
      );
      return false;
    }

    if (type === 'IMAGE' || type === 'PDF') {
      if (newTemplate) {
        const pageId = UUID();
        const page_response = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving page data',
          promise: MatgenGlobal.Data.savePage({
            id: pageId,
            template_id: payload.id,
            number: 0,
          }),
        });

        if (page_response === false) {
          MatgenGlobal.UI.handleError(
            'Server Error',
            'There was a problem saving the page data.'
          );
          return false;
        }

        MatgenGlobal.editor = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving page JSON',
          promise: new MatgenEditor({
            id: payload.id,
            pageId: pageId,
            fonts: payload.fonts ? payload.fonts : null,
            containerId: 'matgen-edit-canvas',
            templateId: payload.id,
          }),
        });

        MatgenGlobal.editor.width = payload.width;
        MatgenGlobal.editor.height = payload.height;

        const editDiv = $(
          `<div id="matgen-edit-canvas" class="matgen-hidden-canvas"/>`
        );
        $('body').append(editDiv);

        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Initializing editor',
          promise: MatgenGlobal.editor.init('matgen-edit-canvas'),
        });
        const pageObj = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading page object',
          promise: MatgenGlobal.UI.getPageObject(),
        });
        const page_file_response = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving page JSON',
          promise: MatgenGlobal.Data.savePageFile(
            pageId,
            pageObj,
            payload.tenant_id
          ),
        });

        if (page_file_response === false) {
          MatgenGlobal.UI.handleError(
            'Server Error',
            'There was a problem saving the page JSON file.'
          );
          return false;
        }
        if (MatgenGlobal.sidebar) {
          window.setTimeout(() => {
            MatgenGlobal.sidebar.markTemplateClean();
          }, 350);
        }

        const preview_response = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving page preview',
          promise: MatgenGlobal.UI.savePagePreview(false, payload.tenant_id),
        });

        if (preview_response === false) {
          MatgenGlobal.UI.handleError(
            'Server Error',
            'There was a problem saving the template preview image file.'
          );
          return false;
        }

        editDiv.remove();
        delete MatgenGlobal.editor;
        MatgenGlobal.Router.goTo(`templates/${payload.id}`);
      } else {
        const row = $('#data-table')
          .DataTable()
          .row($(`td:contains(${payload.id})`).closest('tr'))
          .data();
        payload.tenant_name = row.tenant_name;
        $('#data-table')
          .DataTable()
          .row($(`td:contains(${payload.id})`).closest('tr'))
          .data(payload);
      }
    } else if (type === 'FILE') {
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving template file',
          promise: MatgenGlobal.Data.saveTemplateFile(
            `${payload.id}${payload.file_ext}`,
            tenant_id,
            tfile
          ),
        });
      } catch (e) {
        console.error(e);
      }
      if (pfile) {
        try {
          await MatgenGlobal.MatgenPageLoader.start({
            message: 'Saving template preview',
            promise: MatgenGlobal.Data.saveTemplateFile(
              `${payload.id}${payload.preview_image_ext}`,
              tenant_id,
              pfile
            ),
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
    tfile = false;
    pfile = false;
    if (type === 'FILE') {
      MatgenGlobal.Router.goTo(`tags/${payload.id}`);
    }
  } else {
    $('#template-form')[0].reportValidity();
    window.setTimeout(() => {
      if (
        $('#inputPreviewUpload').length > 0 &&
        !$('#inputPreviewUpload').val()
      ) {
        MatgenGlobal.UI.upload(() => {
          if (document.getElementById('file-input')) {
            pfile = document.getElementById('file-input').files[0];
            $('#inputPreviewUpload').val(
              document.getElementById('file-input').files[0].name
            );
            document
              .querySelector('body')
              .removeChild(document.getElementById('file-input'));
          }
        });
      }
      if (
        $('#inputTemplateFileUpload').length > 0 &&
        !$('#inputTemplateFileUpload').val()
      ) {
        MatgenGlobal.UI.upload(() => {
          if (document.getElementById('template-file')) {
            tfile = document.getElementById('template-file').files[0];
            $('#inputTemplateFileUpload').val(
              document.getElementById('template-file').files[0].name
            );
            document
              .querySelector('body')
              .removeChild(document.getElementById('template-file'));
          }
        }, 'template-file');
      }
    }, 750);
  }
});
*/
