/* global MatgenGlobal, API_ENV, TENANT_ENV */

export const logEnvInfo = () => {
  MatgenGlobal.consoleLog(
    'M4C Matgen™ Ⓒ2019',
    `color:#555;background:${MatgenGlobal.M4CGreen};padding: .5em 20%;font-size:16px;font-weight: bold;`
  );
  MatgenGlobal.consoleLog(
    'https://marketingforchange.com',
    `color:#555;border:1px solid ${MatgenGlobal.M4CGreen};padding: .5em 2em .5em 2.5em;font-weight: bold;`
  );

  MatgenGlobal.consoleLogComplex([
    {
      str: 'API_ENV:',
      rules: `color:${MatgenGlobal.M4CRed};font-weight:bold;`,
    },
    {
      str: API_ENV,
      rules: `color:${MatgenGlobal.M4CBlue};`,
    },
    {
      str: 'TENANT_ENV:',
      rules: `color:${MatgenGlobal.M4CRed};font-weight:bold;`,
    },
    {
      str: TENANT_ENV,
      rules: `color:${MatgenGlobal.M4CBlue};`,
    },
  ]);
  MatgenGlobal.consoleLog(
    'AmplifyConfig',
    `color:${MatgenGlobal.M4CBlue};font-weight:bold;`,
    MatgenGlobal.AmplifyConfig
  );
};

export const pt = x => {
  return (x / 300) * 72;
};

export const emit = ({
  event,
  detail = null,
  target = document,
  bubbles = true,
}) => {
  if (event && target) {
    if (
      MatgenGlobal.debugLoader &&
      event.includes('matgen-event-loader') &&
      event !== 'matgen-event-loader-tick'
    ) {
      console.error('MATGEN CORE EMIT:', event, target);
    }
    target.dispatchEvent(
      new CustomEvent(event, {
        composed: true,
        detail,
        bubbles,
      })
    );
  }
};

export const getQueryParams = (params, url) => {
  const href = url;
  const reg = new RegExp(`[?&]${params}=([^&#]*)`, 'i');
  const queryString = reg.exec(href);
  return queryString ? queryString[1] : null;
};

export const findObjectById = (objects, id) => {
  let obj = false;
  for (let i = 0; i < objects.length; i++) {
    if (objects[i].id === id) {
      return objects[i];
    }
    if (objects[i].getObjects) {
      if (!obj) {
        obj = findObjectById(objects[i].getObjects(), id);
      }
    }
  }
  return obj;
};

export const getUrlId = (loc = window.location) => {
  let url;
  if (
    MatgenGlobal.Options &&
    MatgenGlobal.Options.router &&
    MatgenGlobal.Options.router.hash
  ) {
    url = window.location.hash.replace('#', '');
  } else {
    url = new URL(loc);
    url = url.pathname.replace(/^\/|\/$/g, '');
  }
  const segments = url.split('/');
  if (segments.length == 2) {
    if (
      segments[1].match(
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
      )
    ) {
      return segments[1];
    }
  }
  return false;
};

export const traverse = async (root, visit, children = 'getObjects') => {
  await Promise.all(
    root.map(async o => {
      await visit(o);
      if (o[children]) {
        await traverse(o[children](), visit);
      }
    })
  );
};
