import { Form, TextInput, SelectInput } from '../form/index.js';

const QuestionInputs = [
  new SelectInput({
    label: 'Select Component',
    id: 'inputComponent',
    classes: 'middle',
    options: [
      {
        label: 'Single Select',
        value: 'select-single',
      },
      {
        label: 'Multi Select',
        value: 'select-multiple',
      },
      {
        label: 'Text',
        value: 'text',
      },
      {
        label: 'Other',
        value: 'other',
      },
    ],
  }),
  new TextInput({
    type: 'text',
    label: 'Question',
    id: 'inputText',
    classes: 'middle',
  }),
  new TextInput({
    type: 'subtext',
    label: 'Subtext',
    id: 'inputSubtext',
    classes: 'middle',
  }),
];

const QuestionForm = new Form({
  inputs: QuestionInputs,
  id: 'question-form',
  title: 'Create/Edit Question',
});

export { QuestionForm };
