export const RichTextForm = () => {
  return `
    <div id="form-container" class="container-fluid" style="height:100%;">
      <form
        id="rich-text-form"
        class="matgen-static-form"
        style="min-width:450px;height:100%;"
      >

        <div class="row">
          <div class="col">
            <div class="form-error"></div>
            <div class="form-success"></div>
          </div>
        </div>

        <div class="row" style="height:100%;">
          <div class="col-6">
            <div id="quill-container"><div id="inputRichText" data-lpignore="true"></div></div>
          </div>
          <div class="col-6">
            <div id="rich-text-preview"></div>
          </div>
        </div>

      </form>
    </div>
  `;
};
