/* global $ */

class Uploader {
  bufferUpload(file) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = e => {
        const durl = e.target.result;
        resolve(durl);
      };
      reader.readAsDataURL(file);
    });
  }

  fileSelect(
    id,
    cb,
    targetSelector = 'body',
    accept = '.jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*'
  ) {
    $(`#${id}`).remove();
    const file = document.createElement('input');
    file.setAttribute('type', 'file');
    file.setAttribute('id', id);
    file.setAttribute('accept', accept);
    file.style.display = 'none';
    document.querySelector(targetSelector).appendChild(file);
    document.getElementById(id).addEventListener('change', cb);
    document.getElementById(id).click();
  }
}

export { Uploader };
