/* global $, MatgenGlobal, bootstrap */

import memoize from 'memoizee';

const getUser = memoize(() => MatgenGlobal.AuthUser.getUser(), {
  maxAge: 300000,
  promise: true,
});

$(document).on('hidden.bs.modal', e => {
  if ($(`#${$(e.target).attr('id')}`).length > 0) {
    const modal = bootstrap.Modal.getOrCreateInstance(
      `#${$(e.target).attr('id')}`
    );
    modal.hide();
    $(`#${$(e.target).attr('id')}`).remove();
    if ($('.modal-backdrop.fade.show').length > 1) {
      $('.modal-backdrop.fade.show')
        .not(':first')
        .remove();
    } else {
      $('.modal-backdrop.fade.show').remove();
    }
  }
  $('body').css('overflow', 'initial');
});

$(document).on('click', () => {
  if ($('#context-menu').length > 0) {
    $('#context-menu').remove();
  }
});

$(document).ready(() => {
  if (MatgenGlobal.Options.tenant_env === 'nia') {
    $('#intranet-logo').hide();
    $('#intranet-logo-footer').hide();
  } else {
    $('#intranet-logo').show();
    $('#intranet-logo-footer').show();
  }
});

$(document).on('matgen-ready', async () => {
  $('#page-loader')
    .hide()
    .remove();
  MatgenGlobal.Router.init(MatgenGlobal.Options.router);
  if (await getUser()) {
    if (
      window.location.pathname === '/unauth' ||
      window.location.hash === '#/unauth' ||
      (window.location.pathname === '/' && !window.location.hash) ||
      window.location.hash === '#'
    ) {
      if (MatgenGlobal.AuthUser.getUserRole() !== 'user') {
        MatgenGlobal.Router.goTo('templates');
      } else {
        MatgenGlobal.Router.goTo('materials');
      }
    }
  } else {
    MatgenGlobal.Router.goTo('unauth');
  }
});
