import { Input } from './Input.js';

class ButtonInput extends Input {
  constructor() {
    super(...arguments);
  }
  getHTML() {
    return `
        <button${this.id ? ` id="${this.id}"` : ''} class="btn btn-block mt-3 ${
      this.classes
    }" type="${this.type}">
          ${this.label}
        </button>
    `;
  }
}

export { ButtonInput };
