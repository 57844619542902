/* global MatgenGlobal */

import { Form, TextInput, SelectInput, CheckboxInput } from '../form/index.js';

const QuestionnaireInputs = [
  new TextInput({
    type: 'text',
    label: 'Name',
    id: 'inputName',
    required: true,
  }),
  new SelectInput({
    label: 'Tenant ID',
    id: 'inputTenantID',
    classes: 'middle',
    required: true,
    options: async () => {
      const tenants = await MatgenGlobal.Data.getTenants();
      return tenants.map(t => {
        return {
          label: t.name,
          value: t.id,
        };
      });
    },
  }),
  new SelectInput({
    label: 'Type',
    id: 'inputType',
    classes: 'bottom',
    required: true,
    options: [
      {
        label: 'Material',
        value: 'material',
      },
      {
        label: 'Microsite',
        value: 'microsite',
      },
    ],
  }),
  new CheckboxInput({
    id: 'inputActive',
    label: 'Active',
  }),
];

const QuestionnaireForm = new Form({
  inputs: QuestionnaireInputs,
  id: 'questionnaire-form',
});

export { QuestionnaireForm };
