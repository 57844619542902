import { Form, TextInput, TextAreaInput } from '../form/index.js';

const ExampleInputs = [
  new TextAreaInput({
    label: 'Answer Text',
    id: 'inputText',
    classes: 'top',
  }),
  new TextInput({
    type: 'subtext',
    label: 'Subtext',
    id: 'inputSubtext',
    classes: 'middle',
  }),
  `
  <div id="icon-preview">
    <div id="answer-icon"><i class="fa-duotone fa-circle-question fa-2xl"></i></div>
    <button id="toggle-icon-chooser" type="button" class="btn btn-secondary">Choose Icon</button>
  </div>
  `,
  `
  <input type="hidden" id="question-id" name="question-id"/>
  `,
];

const ExampleForm = new Form({
  inputs: ExampleInputs,
  id: 'answer-form',
  title: 'Create/Edit Example',
});

export { ExampleForm };
