/* global MatgenGlobal */

import { Form, TextInput, SelectInput, ButtonInput } from '../form/index.js';

const TemplateInputs = [
  new TextInput({
    type: 'text',
    label: 'Name',
    id: 'inputTemplateName',
    dataId: 'name',
    classes: 'top',
    required: true,
    autofocus: true,
  }),
  new TextInput({
    type: 'text',
    label: 'Subtitle',
    id: 'inputTemplateSubtitle',
    dataId: 'subtitle',
    classes: 'middle',
    autofocus: true,
  }),
  new SelectInput({
    label: 'Tenant',
    id: 'inputTenantID',
    dataId: 'tenant_id',
    classes: 'middle',
    required: true,
    options: async () => {
      const tenants = await MatgenGlobal.Data.getTenants();
      return tenants.map(t => {
        return {
          label: t.name,
          value: t.id,
        };
      });
    },
  }),
  new SelectInput({
    label: 'Type',
    id: 'inputTemplateType',
    dataId: 'type',
    classes: 'middle',
    required: true,
    options: [
      {
        label: 'PDF',
        value: 'PDF',
      },
      {
        label: 'IMAGE',
        value: 'IMAGE',
      },
      {
        label: 'FILE',
        value: 'FILE',
      },
    ],
  }),
  new TextInput({
    type: 'number',
    label: 'Width',
    id: 'inputWidth',
    dataId: 'width',
    classes: 'middle',
  }),
  new TextInput({
    type: 'number',
    label: 'Height',
    id: 'inputHeight',
    dataId: 'height',
    classes: 'middle',
  }),
  new SelectInput({
    label: 'Preview Type',
    id: 'inputPreviewType',
    dataId: 'preview_type',
    classes: 'middle',
    required: true,
    options: [
      {
        label: 'Image',
        value: 'IMAGE',
      },
      {
        label: 'Link',
        value: 'LINK',
      },
      {
        label: 'Self',
        value: 'SELF',
      },
    ],
  }),
  new TextInput({
    type: 'url',
    label: 'Preview Link',
    id: 'inputPreviewLink',
    dataId: 'preview_link',
    classes: 'middle',
  }),
  new TextInput({
    type: 'text',
    label: 'Instructions',
    id: 'inputInstructions',
    dataId: 'instructions',
    classes: 'middle',
  }),
  new TextInput({
    type: 'text',
    label: 'Text Length',
    id: 'inputTextLength',
    dataId: 'text_length',
    classes: 'bottom',
  }),
  new ButtonInput({
    type: 'submit',
    label: 'Submit',
    id: 'template-form-submit',
    classes: 'btn-lg btn-primary',
  }),
];

const TemplateForm = new Form({
  inputs: TemplateInputs,
  id: 'template-form',
  title: 'Create/Edit Template',
});

export { TemplateForm };
