/* global $, MatgenGlobal */

import MatgenUIFunctions from '../../../matgen-ui/ui-functions.js';

$(document).on('matgen-table-action', e => {
  console.log('matgen-table-action:', e.detail);
  let data;
  switch (e.detail.action) {
    default:
      break;
    case 'template-mark-tested':
      data = $(MatgenGlobal.Tables.TemplateTable.tableSelector)
        .DataTable()
        .row($(e.detail.row))
        .data();
      if (parseInt(data.tested) === 1) {
        console.log('Mark untested');
      } else {
        console.log('Mark tested');
      }
      break;
    case 'new-tenant':
      MatgenUIFunctions.saveForm('tenant', 'Tenant');
      break;
    case 'new-questionnaire':
      MatgenGlobal.Router.goTo(`/tenant-questionnaire/${e.detail.id}`);
      break;
    case 'new-matgen_questionnaire':
      //MatgenUIFunctions.saveForm('questionnaire', 'Questionnaire');
      // MatgenGlobal.Router.goTo(`/tenant-questionnaire/${e.detail.id}`);
      MatgenUIFunctions.createQuestionnaire();
      break;
    case 'new-user_content_questionnaire':
      //MatgenUIFunctions.saveForm('questionnaire', 'Questionnaire');
      // MatgenGlobal.Router.goTo(`/tenant-questionnaire/${e.detail.id}`);
      MatgenUIFunctions.createQuestionnaire();
      break;
    case 'new-section':
      MatgenUIFunctions.saveForm('section', 'Section');
      break;
    case 'new-question':
      MatgenUIFunctions.saveForm('question', 'Question');
      break;
    case 'new-answer':
      MatgenUIFunctions.saveForm('answer', 'Answer');
      break;
    case 'questionnaire-delete':
      MatgenGlobal.UI.confirm(
        'Confirm Delete',
        'Are you sure you wish to delete this questionnaire?',
        'Continue',
        'Cancel',
        () => MatgenUIFunctions.deleteQuestionnaire(e.detail.id)
      );
      break;

    case 'questionnaire-view-sections':
      MatgenUIFunctions.viewSections(e.detail.id);
      break;

    case 'questionnaire-edit':
      MatgenGlobal.Router.goTo(`edit-questionnaire/${e.detail.id}`);
      //MatgenUIFunctions.saveForm('questionnaire', 'Questionnaire', e.detail.id);
      break;
  }
});
