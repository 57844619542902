/* global $, MatgenGlobal, M4CGlobal */

import { MatgenCanvas } from './canvas/matgen-canvas.js';
import { fabric } from 'fabric';
import { v4 as UUID } from 'uuid';
import { getQueryParams as GetQueryParams } from '../matgen-ui/common/helpers.js';
import { DrawingCanvas } from './canvas/drawing-canvas.js';
import { ReferenceCanvas } from './canvas/reference-canvas.js';
import { SidebarHeader } from '../matgen-ui/components/Sidebar/SidebarHeader.js';

const PREVIEW_WIDTH = 800;

class MatgenEditor {
  constructor({
    id,
    templateId,
    pageId,
    width,
    height,
    containerId = false,
    containerSelector = false,
  } = {}) {
    this.canvas = false;
    this.templateId = templateId;
    this.canvases = [];
    this.currentCanvas = null;
    this.groupItems = [];
    this.canvasEditable = false;
    this.width = width;
    this.height = height;
    this.id = id;
    this.curPageId = pageId;
    this.containerId = containerId ? containerId : MatgenGlobal.ContainerId;
    this.containerSelector = containerSelector
      ? containerSelector
      : MatgenGlobal.containerSelector;
    this.editingComponent = null;
    this.fonts = [];
    this.fabric = fabric;
    this.matId = GetQueryParams('materialId', location.search);
    this.modalState = {
      open: false,
    };

    if (!M4CGlobal.pickr) {
      M4CGlobal.pickr = {};
    }
  }

  cur() {
    return this.canvases[this.currentCanvas];
  }

  async load({
    canvasContainerId = 'matgen-scale-container-default',
    json,
    template,
    targetSelector = '#matgen-editor',
    cb = false,
    keepLoading = false,
    suppressLoader = false,
  } = {}) {
    this.targetSelector = targetSelector;
    this.canvasContainerId = canvasContainerId;

    $(targetSelector).append(
      $(
        `<div id="${canvasContainerId}-scaler" class="editor-scaler"><div id="${canvasContainerId}" class="canvas-container"></div></div>`
      )
    );
    try {
      const canvas = new MatgenCanvas({
        id: this.id,
        canvasContainerId,
        width: template.width,
        height: template.height,
        editor: this,
        backgroundColor: 'white',
      });
      this.templateWidth = template.width;
      this.templateHeight = template.height;
      $(`#${canvasContainerId}`).append(
        $(canvas.display('matgen-canvas', 'contained-canvas'))
      );

      this.canvases[this.id] = canvas;
      this.currentCanvas = this.id;
      canvas.initCanvas(`${canvasContainerId}-canvas`);
      canvas.scale(template.width, template.height);
      if (json) {
        await canvas.loadJSON(
          { fabric: json, fonts: template.fonts },
          cb,
          keepLoading,
          suppressLoader
        );
      }

      canvas.initCanvasEvents();

      canvas.fabric.backgroundColor = 'white';

      canvas.fabric.renderAll();
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async init(canvasContainerId) {
    let mainCanvas;
    try {
      mainCanvas = new MatgenCanvas({
        id: this.id,
        canvasContainerId: canvasContainerId,
        width: this.width,
        height: this.height,
        editor: this,
        backgroundColor: 'white',
      });
    } catch (e) {
      console.error(e);
      return false;
    }

    $(canvasContainerId).append(mainCanvas.display('matgen-canvas'));

    mainCanvas.initCanvas('matgen-canvas');
    mainCanvas.fabric.backgroundColor = 'white';
    mainCanvas.fabric.renderAll();
    mainCanvas.initCanvasEvents();

    mainCanvas.scale(this.width, this.height);

    this.canvases[this.id] = mainCanvas;
    this.currentCanvas = this.id;

    mainCanvas.fabric.renderAll();
  }

  loadReferenceCanvas({ src } = {}) {
    MatgenGlobal.editor
      .cur()
      .fabricJS.Image.fromURL(
        src,
        img =>
          (MatgenGlobal.reference = new ReferenceCanvas(
            this.canvasContainerId,
            img
          ))
      );
  }

  async loadDrawingCanvas(opts) {
    MatgenGlobal.drawingCanvas = new DrawingCanvas(
      this.canvasContainerId,
      opts
    );
    MatgenGlobal.sidebarAddButton = $('#template-add-item').clone();
    const dropHTML = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading drawing canvas',
      promise: SidebarHeader.headerDropDownHTML(
        'drawing-options',
        'Drawing Options',
        `fa-solid fa-pen-line`,
        false,
        [
          {
            id: 'add-shapes',
            title: 'Add shape(s) to canvas',
            icon: `fa-solid fa-circle-plus`,
            classes: ['add-shapes-to-canvas'],
          },
          {
            id: 'cancel-drawing',
            title: 'Cancel drawing',
            icon: `fa-regular fa-circle-xmark`,
            classes: ['cancel-canvas-drawing'],
          },
        ]
      ),
    });
    $('#template-add-item').replaceWith($(dropHTML));
  }

  resetZoom() {
    this.canvases[this.id].scale(this.width, this.height);
    this.canvases[this.id].fabric.renderAll();
  }

  async setComponentOption(id, componentId, name, groupNames) {
    MatgenGlobal.settingOption = {
      removed: false,
      added: false,
    };

    const componentItems = this.cur()
      .fabric.getObjects()
      .filter(el => el.componentId === componentId);
    const component = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading component',
      promise: MatgenGlobal.Data.getComponent(componentId),
    });
    const template = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template',
      promise: MatgenGlobal.Data.getTemplate(component.template_id),
    });
    let json = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading option JSON',
      promise: MatgenGlobal.Data.getComponentOption(id, template.tenant_id),
    });

    let allowUploads = false;

    if (!name && componentItems.length < 2) {
      name = componentItems[0].name;
    }

    if (componentItems.length < 2) {
      allowUploads = componentItems[0].allowUploads;
    }

    const index = this.cur()
      .fabric.getObjects()
      .findIndex(el => el.componentId === componentId);
    if (componentItems.length < 2) {
      const oldId = componentItems[0].id;
      json.componentReadOrder = componentItems[0].componentReadOrder;
      json.componentPdfTag = componentItems[0].componentPdfTag;
      json.name = name;
      json.allowUploads = allowUploads;
      let openItems = sessionStorage.getItem('matgen-tree-state');
      if (!openItems) {
        openItems = [];
      } else {
        openItems = JSON.parse(openItems);
      }
      if (openItems.includes(oldId)) {
        openItems.splice(openItems.indexOf(oldId), 1);
        openItems.push(json.id);
        sessionStorage.setItem('matgen-tree-state', JSON.stringify(openItems));
      }

      this.cur().fabric.remove(componentItems[0]);
      const options = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading component options',
        promise: MatgenGlobal.Data.getComponentOptions(componentId),
      });
      const hasOptions = options && options.length && options.length > 1;
      this.enlivenOption([json], index, id, hasOptions);
    } else {
      componentItems.forEach(o => {
        this.cur().fabric.remove(o);
      });
      if (!Array.isArray(json)) {
        json = [json];
      }

      for (let i = 0; i < groupNames.length; i++) {
        json[0].name = groupNames[i].componentName;
        json[0].objects[i].name = groupNames[i].name;
        json[0].objects[i].componentName = groupNames[i].componentName;
      }

      this.enlivenOption(json, index, id, true);
    }
  }

  enlivenOption(objects, i = 0, optionId = false, hasOptions) {
    return new Promise(resolve => {
      fabric.util.enlivenObjects(objects, enlivenedObjects => {
        enlivenedObjects.forEach(obj => {
          if (
            !MatgenGlobal.AuthUser.user ||
            MatgenGlobal.AuthUser.getUserRole() === 'user'
          ) {
            obj.hasControls = false;
            obj.lockRotation = true;
            obj.lockMovementX = true;
            obj.lockMovementY = true;
            obj.lockScalingX = true;
            obj.lockScalingY = true;
            obj.lockUniScaling = true;
            obj.editable = false;
            obj.selectable =
              obj.studyDataConnection ||
              (obj.componentId && hasOptions) ||
              obj.richText
                ? true
                : false;
            obj.evented = obj.selectable ? true : false;
          }
          if (
            MatgenGlobal.AuthUser &&
            MatgenGlobal.AuthUser.getUserRole() === 'admin'
          ) {
            obj.hasControls = false;
            obj.lockRotation = true;
            obj.lockMovementX = true;
            obj.lockMovementY = true;
            obj.lockScalingX = true;
            obj.lockScalingY = true;
            obj.lockUniScaling = true;
            obj.editable = false;
            obj.selectable = !obj.userEditable;
          }
          if (
            MatgenGlobal.AuthUser &&
            MatgenGlobal.AuthUser.getUserRole() === 'super'
          ) {
            obj.hasControls = true;
            obj.lockRotation = true;
            obj.hasRotatingPoint = false;
            obj.lockMovementX = false;
            obj.lockMovementY = false;
            obj.lockScalingX = false;
            obj.lockScalingY = false;
            obj.lockUniScaling = false;
            obj.editable = true;
            obj.selectable = obj.layerLocked !== true;
            obj.visible = obj.layerVisible !== false;
          }
          if (optionId !== false) {
            obj.currentOptionId = optionId;
          }
          obj.hasOptions = hasOptions;

          let name = obj.name;
          let allowUploads = obj.allowUploads;
          if (obj.type == 'group') {
            obj.getObjects().forEach(o => {
              name = o.name;
              allowUploads = o.allowUploads;
            });
          }

          obj.name = name;
          obj.allowUploads = allowUploads;
          if (
            MatgenGlobal.editor.cur().fabric.themeColor &&
            obj.useThemeColor
          ) {
            //console.error('SET THEME COLOR:', obj, MatgenGlobal.editor.cur().fabric.themeColor);
            obj.fill = MatgenGlobal.editor.cur().fabric.themeColor;
          }
          this.cur().fabric.insertAt(obj, i);

          if (obj.type == 'group' && MatgenGlobal.AuthUser.hasRole('admin')) {
            obj.getObjects().forEach(o => {
              o.hasControls = false;
            });
          }
          if (obj.type == 'group' && MatgenGlobal.AuthUser.isM4CSuper()) {
            const items = obj._objects;
            obj._restoreObjectsState();
            this.cur().fabric.remove(obj);
            for (let i = 0; i < items.length; i++) {
              delete items[i].groupId;
              if (obj.currentOptionId) {
                items[i].currentOptionId = obj.currentOptionId;
              }
              this.cur().fabric.add(items[i]);
              this.cur().fabric.item(
                this.cur().fabric.size() - 1
              ).hasControls = true;
            }
            let openItems = sessionStorage.getItem('matgen-tree-state');
            if (!openItems) {
              openItems = [];
            } else {
              openItems = JSON.parse(openItems);
            }
            openItems.splice(openItems.indexOf(obj.id), 1);
            sessionStorage.setItem(
              'matgen-tree-state',
              JSON.stringify(openItems)
            );
          }
        });
        this.cur().fabric.renderAll();
        resolve();
      });
    });
  }

  async loadSelectedOptions() {
    try {
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading selected options',
        promise: Promise.all(
          this.cur()
            .fabric.getObjects()
            .map(async o => {
              if (o.type === 'group') {
                const items = o._objects;
                o._restoreObjectsState();
                this.cur().fabric.remove(o);
                for (let i = 0; i < items.length; i++) {
                  this.cur().fabric.add(items[i]);
                  this.cur().fabric.item(
                    this.cur().fabric.size() - 1
                  ).hasControls = true;
                }

                this.cur().fabric.renderAll();
              }
            })
        ),
      });
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async loadDefaults() {
    try {
      let page = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template page JSON',
        group: 'load-defaults',
        promise: MatgenGlobal.Data.getTemplateFile(this.curPageId),
      });
      page = JSON.stringify(page);
      //const oldPage = page;
      const pageChanged = false;
      const template = false;

      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Checking for crossed components',
        promise: Promise.all(
          this.cur()
            .fabric.getObjects()
            .map(async (o, i) => {
              let defaultOption, saveOptions;

              if (o.componentId && !o.uploader) {
                try {
                  let cid = o.componentId;
                  if (typeof cid === 'object') {
                    cid = cid.componentId;
                  }

                  defaultOption = await MatgenGlobal.MatgenPageLoader.start({
                    message: 'Loading default option data',
                    promise: MatgenGlobal.Data.getDefaultOption(cid),
                  });

                  if (defaultOption !== false) {
                    saveOptions = await MatgenGlobal.MatgenPageLoader.start({
                      message: 'Loading component options',
                      group: 'load-options',
                      promise: MatgenGlobal.Data.getComponentOptions(cid),
                    });

                    const hasOptions =
                      saveOptions &&
                      saveOptions.length &&
                      saveOptions.length > 1;

                    this.cur().fabric.remove(o);
                    if (!Array.isArray(defaultOption.json)) {
                      defaultOption.json = [defaultOption.json];
                    }

                    await MatgenGlobal.MatgenPageLoader.start({
                      message: 'Enliven option',
                      promise: this.enlivenOption(
                        defaultOption.json,
                        i,
                        defaultOption.id,
                        hasOptions
                      ),
                    });
                  } else {
                    console.error(Error('Failed to find default option JSON'));
                  }
                } catch (e) {
                  console.error(e);
                  return false;
                }

                this.cur().fabric.requestRenderAll();
              }
            })
        ),
      });

      if (pageChanged === true && template !== false) {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving page JSON',
          promise: MatgenGlobal.Data.savePageFile(
            this.curPageId,
            JSON.parse(page),
            template.tenant_id
          ),
        });
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async loadImageEditor(json, type = 'template', target = 'body') {
    this.width = json.width;
    this.height = json.height;
    this.type = json.type;
    this.id = json.id;
    this.fonts = json.fonts;
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Initializing editor',
      promise: this.init({ target }),
    });

    return new Promise((resolve, reject) => {
      this.cur().loadJSON(json, async () => {
        if (type === 'template') {
          try {
            await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading default options',
              promise: this.loadDefaults(json.id),
            });
            resolve(this);
          } catch (e) {
            console.error(e);
            reject(e);
          }
        } else {
          try {
            await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading selected options',
              promise: this.loadSelectedOptions(json.id),
            });
            resolve(this);
          } catch (e) {
            console.error(e);
            reject(e);
          }
        }
      });
    });
  }

  async getTemplate(id, pageId) {
    try {
      const data = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template',
        promise: MatgenGlobal.Data.getTemplate(id),
      });
      const fabric = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template JSON',
        promise: MatgenGlobal.Data.getTemplateFile(pageId),
      });
      return {
        data,
        fabric,
      };
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getMaterial(id, pageId) {
    try {
      const material = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading material',
        promise: MatgenGlobal.Data.getMaterial(id),
      });
      const data = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template',
        promise: MatgenGlobal.Data.getTemplate(material.template_id),
      });
      const fabric = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading material JSON',
        promise: MatgenGlobal.Data.getMaterialPageFile(id, pageId),
      });
      return {
        data,
        fabric,
      };
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  loadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.addEventListener('load', () => resolve(img));
      img.addEventListener('error', err => reject(err));
      img.src = src;
    });
  }

  async generateOptionPreview(o, fullsize = false) {
    const objJSON = {
      width: o.width,
      height: o.height,
      fabric: o,
    };
    o = objJSON;
    const id = UUID();
    const editDiv = $(
      `<div id="matgen-edit-canvas-${id}" class="matgen-hidden-canvas"/>`
    );
    $('body').append(editDiv);
    const editCanvas = new MatgenCanvas({
      id,
      canvasContainerId: `matgen-edit-canvas-${id}`,
      width: o.width,
      height: o.height,
    });

    editDiv.append($(editCanvas.display(`edit-canvas-${id}`, 'hidden-canvas')));
    editCanvas.initCanvas(`edit-canvas-${id}`);

    editCanvas.fabric.backgroundColor = null;

    if (o.type !== 'group') {
      editCanvas.fabric.setZoom(this.cur().fabric.getZoom());
    }
    editCanvas.fabric.setWidth(o.width);
    editCanvas.fabric.setHeight(o.height);

    let scaleRatio = 1;
    if (!fullsize && o.width > PREVIEW_WIDTH) {
      scaleRatio = PREVIEW_WIDTH / o.width;
    }

    editCanvas.fabric.setDimensions({
      width: o.width * scaleRatio,
      height: o.height * scaleRatio,
    });

    editCanvas.fabric.setZoom(scaleRatio);

    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Enliven canvas object',
      promise: this.enliven([o.fabric.toJSON()], editCanvas),
    });
    editCanvas.fabric.requestRenderAll();

    const durl = editCanvas.fabric.toDataURL({ format: 'png' });
    $(`#matgen-edit-canvas-${id}`).remove();
    return durl;
  }

  async enliven(o, editCanvas) {
    return new Promise(resolve => {
      fabric.util.enlivenObjects(o, enlivenedObjects => {
        enlivenedObjects.forEach(obj => {
          //obj.originX = 'left';
          //obj.originY = 'top';
          obj.top = 0;
          obj.left = 0;
          editCanvas.fabric.add(obj);
          obj.scaleToWidth(editCanvas.fabric.getWidth());
        });
        editCanvas.fabric.requestRenderAll();
        resolve(true);
      });
    });
  }
}

export { MatgenEditor };
