import * as MatgenForms from '../forms';

class LoginForm {
  constructor() {
    const inputs = [
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Email',
          id: 'm4c-login-email',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'password',
          label: 'Password',
          id: 'm4c-login-password',
        },
      },
    ];

    return new MatgenForms.DynamicForm(inputs, [], 'm4c-login-form');
  }
}
export { LoginForm };
