/* global Quill, $, M4CGlobal, bootstrap */

import M4CColorPicker from './M4CColorPicker';

class M4CRichTextEditor {
  constructor({
    id,
    options,
    theme = 'snow',
    initCallback,
    changeHandler,
    minHeight,
    targetSelector,
  } = {}) {
    let selector = `#input-rich-text-${id}`;
    if (targetSelector) {
      selector = targetSelector;
    }
    if ($(selector).length === 0) {
      throw new Error('Missing rich text input field');
    }
    this.id = id;
    if (typeof M4CGlobal === 'undefined') {
      window.M4CGlobal = {};
    }

    if (!M4CGlobal.quill) {
      M4CGlobal.quill = {};
    }
    this.options = [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      ['color'],
      [{ align: [] }],

      ['clean'],
    ];
    if (options) {
      this.option = options;
    }
    this.quill = new Quill(selector, {
      modules: {
        toolbar: this.options,
      },
      theme,
    });
    M4CGlobal.quill[id] = this.quill;
    M4CGlobal.quill[id].focus();

    M4CColorPicker(
      {
        el: '.ql-color',
        container: '.ql-toolbar',
        default: '#000000',
        useAsButton: true,
        position: 'bottom-middle',
      },
      'rich-text'
    );

    const _this = this;
    $(document).off('matgen-color-picked', e => {
      _this.setRichTextColor(e, _this);
    });
    $(document).on('matgen-color-picked', e => {
      _this.setRichTextColor(e, _this);
    });

    if (changeHandler && typeof changeHandler === 'function') {
      M4CGlobal.quill[id].on('text-change', () => {
        changeHandler({
          html: M4CGlobal.quill[id].root.innerHTML,
          plain: M4CGlobal.quill[id].getText(),
          data: M4CGlobal.quill[id].getContents(),
        });
        /*console.log({
          html: M4CGlobal.quill[id].root.innerHTML,
          plain: M4CGlobal.quill[id].getText(),
          data: M4CGlobal.quill[id].getContents(),
        });*/
      });
    }

    M4CGlobal.quill[id].clipboard.addMatcher(
      Node.ELEMENT_NODE,
      (node, delta) => {
        const ops = [];
        delta.ops.forEach(op => {
          if (op.insert && typeof op.insert === 'string') {
            ops.push({
              insert: op.insert,
            });
          }
        });
        delta.ops = ops;
        return delta;
      }
    );

    const Parchment = Quill.import('parchment');

    class CustomColor extends Parchment.Attributor.Style {
      value(node) {
        let value = super.value(node);

        if (!value.startsWith('rgb(')) return value;

        value = value.replace(/^[^\d]+/, '').replace(/[^\d]+$/, '');

        return `#${value
          .split(',')
          .map(component => {
            return `00${parseInt(component, 10).toString(16)}`.slice(-2);
          })
          .join('')}`;
      }
    }

    const customColorAttributor = new CustomColor('custom-color', 'color', {
      scope: Parchment.Scope.BLOCK,
    });

    Quill.register(customColorAttributor);

    const ListItem = Quill.import('formats/list/item');

    class ColoredListItem extends ListItem {
      optimize(context) {
        super.optimize(context);

        if (this.children.length === 1) {
          const child = this.children.head;
          const attributes = child.attributes;

          if (attributes && attributes.attributes.color) {
            const color = attributes.attributes.color.value(child.domNode);
            super.format('custom-color', color);
          }
        } else {
          /* eslint-disable-next-line no-prototype-builtins */
          if (this.attributes.attributes.hasOwnProperty('custom-color')) {
            super.format('custom-color', null);
          }
        }
      }
    }

    Quill.register(ColoredListItem, true);

    $('.ql-snow .ql-tooltip input[type=text]').attr('data-lpignore', 'true');
    $('.ql-color-label').css('cssText', 'opacity: 1 !important');

    if (minHeight) {
      $('.ql-container.ql-snow').css('min-height', minHeight);
    }

    this.initRichTextEditorTooltips();

    if (initCallback && typeof initCallback === 'function') {
      initCallback();
    }
  }

  setRichTextColor(e, scope) {
    scope.quill.format('color', e.detail.color);
    $('.ql-color-label').css('stroke', e.detail.color);
  }

  initRichTextEditorTooltips() {
    const tooltips = [
      {
        class: '.ql-clean',
        tooltip: 'Remove formatting',
      },
      {
        class: '.ql-align',
        tooltip: 'Alignment',
      },
      {
        class: '.ql-color',
        tooltip: 'Color',
      },
      {
        class: '.ql-script[value=sub]',
        tooltip: 'Subscript',
      },
      {
        class: '.ql-script[value=super]',
        tooltip: 'Superscript',
      },
      {
        class: '.ql-list[value=bullet]',
        tooltip: 'Bulleted List',
      },
      {
        class: '.ql-list[value=ordered]',
        tooltip: 'Ordered List',
      },
      {
        class: '.ql-underline',
        tooltip: 'Underline',
      },
      {
        class: '.ql-italic',
        tooltip: 'Italic',
      },
      {
        class: '.ql-bold',
        tooltip: 'Bold',
      },
    ];
    for (let i = 0; i < tooltips.length; i++) {
      $(tooltips[i].class)
        .attr('data-bs-toggle', 'tooltip')
        .attr('title', tooltips[i].tooltip)
        .attr('data-bs-title', tooltips[i].tooltip)
        .attr('data-bs-delay', '{"show":"5000", "hide":"3000"}');
    }

    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].map(
      tooltipTriggerEl =>
        new bootstrap.Tooltip(tooltipTriggerEl, {
          delay: {
            show: 1200,
            hide: 0,
          },
        })
    );
  }
}

export default M4CRichTextEditor;
