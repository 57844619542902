/* global $, MatgenGlobal */

import { fabric } from 'fabric';

class ReferenceCanvas {
  constructor(canvasContainerId, src) {
    this.canvasContainerId = canvasContainerId;

    $(`#${canvasContainerId}`).append(
      `
        <div id="reference-canvas-container" class="matgen-reference">
          <canvas id="reference-canvas"></canvas>
        </div>
      `
    );

    MatgenGlobal.editor.cur().fabric.backgroundColor = null;
    MatgenGlobal.editor.cur().fabric.renderAll();

    this.canvas = new fabric.Canvas('reference-canvas', { interactive: false });

    this.canvas.setWidth(MatgenGlobal.editor.templateWidth);
    this.canvas.setHeight(MatgenGlobal.editor.templateHeight);

    const scalingFactor = this.getScalingFactor(
      MatgenGlobal.editor.templateWidth,
      MatgenGlobal.editor.templateHeight
    );

    this.canvas.setDimensions({
      width: this.canvas.getWidth() * scalingFactor,
      height: this.canvas.getHeight() * scalingFactor,
    });
    this.canvas.setZoom(scalingFactor);
    this.canvas.requestRenderAll();

    this.canvas.add(src);
    this.canvas.requestRenderAll();
  }

  getScalingFactor(width, height) {
    const computedStyle = getComputedStyle(
      document.getElementById(this.canvasContainerId)
    );

    const cwidth =
      parseInt(computedStyle.width) -
      parseInt(computedStyle.marginLeft) -
      parseInt(computedStyle.marginRight) -
      parseInt(computedStyle.paddingLeft) -
      parseInt(computedStyle.paddingRight) -
      (parseInt(computedStyle['border-left-width']) +
        parseInt(computedStyle['border-right-width']));
    const cheight =
      parseInt(computedStyle.height) -
      parseInt(computedStyle.marginTop) -
      parseInt(computedStyle.marginBottom) -
      parseInt(computedStyle.paddingTop) -
      parseInt(computedStyle.paddingBottom) -
      (parseInt(computedStyle['border-top-width']) +
        parseInt(computedStyle['border-bottom-width']));

    const scalingFactor = Math.min(cwidth / width, cheight / height);
    this.scalingFactor = scalingFactor;
    return scalingFactor;
  }
}

export { ReferenceCanvas };
