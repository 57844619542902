/* global $, MatgenGlobal, M4CGlobal, Quill, bootstrap */

import { MatgenEditor } from '../../core/matgen-editor.js';
import domtoimage from 'dom-to-image';
import { debounce } from 'throttle-debounce';
import { RichTextForm } from '../../m4c-form/StaticForms/RichTextForm.js';

export const debouncedrichTextEditorUpdate = debounce(250, false, opts => {
  richTextEditorUpdate(opts);
});

export const updateRichTextColor = (obj, color, opacity) => {
  MatgenGlobal.richTextObj = obj;
  $('body').append(RichTextForm());
  loadRichTextFonts();

  MatgenGlobal.richTextObj.set({ fill: `${color}${opacity}` });

  window.setTimeout(() => {
    RichTextEditor(obj);
    richTextEditorUpdate({
      cb: () => {
        $('#rich-text-form').remove();
        $('#quill-output-wrapper').remove();
      },
      preview: false,
    });
  }, 100);
};

const loadFont = url => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          let css = xhr.responseText;
          css = css.replace(/}/g, 'font-display: swap; }');

          const head = document.getElementsByTagName('head')[0];
          const style = document.createElement('style');
          style.appendChild(document.createTextNode(css));
          head.appendChild(style);
          resolve();
        } else {
          reject(xhr);
        }
      }
    };
    xhr.send();
  });
};

export const loadRichTextFonts = async () => {
  const links = $('link');
  const promises = [];
  for (let i = 0; i < links.length; i++) {
    if (
      $(links[i])
        .attr('href')
        .includes('fonts.googleapis.com')
    ) {
      try {
        promises.push(loadFont($(links[i]).attr('href')));
      } catch (e) {
        console.error(e);
      }
    }
  }

  await Promise.all(promises);
  MatgenGlobal.richTextFontsLoaded = true;
};

export const richTextPreviewLoader = () => {
  $('#rich-text-preview').empty();
  $('#rich-text-preview').prepend(
    $(`
      <div id="rich-text-preview-loader"><div>Updating preview...</div></div>
    `)
  );
};

export const richTextEditorUpdate = async ({
  preview = true,
  save = false,
  cb = false,
  id,
} = {}) => {
  const newWidth =
    MatgenGlobal.richTextObj.originalWidth *
    MatgenGlobal.richTextObj.originalScaleX;
  const newHeight =
    MatgenGlobal.richTextObj.originalHeight *
    MatgenGlobal.richTextObj.originalScaleY;
  if (!id) {
    id = MatgenGlobal.richTextObj.id;
  }
  domtoimage
    .toPng($('#quill-output')[0])
    .then(dataUrl => {
      MatgenGlobal.editor.cur().fabricJS.Image.fromURL(dataUrl, img => {
        img.left = MatgenGlobal.richTextObj.left;
        img.top = MatgenGlobal.richTextObj.top;
        img.width = newWidth;
        img.height = newHeight;
        img.scaleX = 1;
        img.scaleY = 1;
        img.richText = true;
        delete img.richTextSizer;
        delete img.name;
        img.name = MatgenGlobal.richTextObj.name || 'Rich Text';
        img.fontFamily = MatgenGlobal.richTextObj.fontFamily;
        img.fontWeight = MatgenGlobal.richTextObj.fontWeight;
        img.fontSize = MatgenGlobal.richTextObj.fontSize;
        img.fontStyle = MatgenGlobal.richTextObj.fontStyle;
        img.fontType = MatgenGlobal.richTextObj.fontType;
        img.textAlign = MatgenGlobal.richTextObj.textAlign;
        img.lineHeight = MatgenGlobal.richTextObj.lineHeight;
        img.fontspec = MatgenGlobal.richTextObj.fontspec;
        img.fill = MatgenGlobal.richTextObj.fill;
        img.useThemeColor = MatgenGlobal.richTextObj.useThemeColor;
        img.themeColorOpacity = MatgenGlobal.richTextObj.themeColorOpacity;
        img.id = MatgenGlobal.richTextObj.id;
        img.bringToFront();

        img.quillData = M4CGlobal.quill[id]
          ? JSON.stringify(M4CGlobal.quill[id].getContents())
          : false;
        img.plainText = M4CGlobal.quill[id]
          ? JSON.stringify(M4CGlobal.quill[id].getText())
          : false;

        img.originalWidth = MatgenGlobal.richTextObj.originalWidth;
        img.originalHeight = MatgenGlobal.richTextObj.originalHeight;
        img.originalTop = MatgenGlobal.richTextObj.originalTop;
        img.originalLeft = MatgenGlobal.richTextObj.originalLeft;
        img.originalScaleX = MatgenGlobal.richTextObj.originalScaleX;
        img.originalScaleY = MatgenGlobal.richTextObj.originalScaleY;

        img.lockMovementX = true;
        img.lockMovementY = true;
        img.lockScalingX = true;
        img.lockScalingY = true;
        img.lockRotation = true;

        img.readOrder = MatgenGlobal.richTextObj.readOrder;
        img.componentReadOrder = MatgenGlobal.richTextObj.componentReadOrder;
        img.componentPdfTag = MatgenGlobal.richTextObj.componentPdfTag;
        img.pdfTag = MatgenGlobal.richTextObj.pdfTag;
        img.altTag = MatgenGlobal.richTextObj.altTag;
        img.uneditable = MatgenGlobal.richTextObj.uneditable;

        img.setControlsVisibility({
          mt: false,
          mb: false,
          ml: false,
          mr: false,
          bl: false,
          br: false,
          tl: false,
          tr: false,
          mtr: false,
        });

        MatgenGlobal.editor.cur().fabric.add(img);
        MatgenGlobal.editor.cur().fabric.remove(MatgenGlobal.richTextObj);
        MatgenGlobal.richTextObj = img;
        MatgenGlobal.editor.cur().fabric.renderAll();

        if (preview) {
          richTextEditorPreview(MatgenGlobal.richTextObj);
        }
        if (save) {
          richTextEditorDone();
        }
        if (cb && typeof cb === 'function') {
          cb();
        }
      });
    })
    .catch(e => {
      console.error(e);
      $('#rich-text-preview-loader').remove();
      MatgenGlobal.UI.handleError(
        'Error',
        '<p>There was en error creating the rich text component.</p>]'
      );
    });
};

export const richTextEditorDone = async () => {
  bootstrap.Modal.getOrCreateInstance('#rich-text-editor').hide();
  window.setTimeout(() => {
    MatgenGlobal.sidebar.refresh(MatgenGlobal.sidebar, null, 'add-rich-text');
    MatgenGlobal.sidebar.markTemplateDirty();
    $('#rich-text-preview-loader').remove();
  }, 250);
};

export const scaleAndPositionPreview = curObj => {
  const f = MatgenGlobal.previewEditor.cur().fabricJS;
  const pz = MatgenGlobal.previewEditor.cur().fabric.getZoom();

  const pObj = MatgenGlobal.UI.findById(
    MatgenGlobal.previewEditor.cur().fabric.getObjects(),
    curObj.id
  );
  const previewPaddingHoriz = 0.1 * pObj.width * pz;
  const previewPaddingVert = 0.1 * pObj.height * pz;
  const pt = new f.Point(
    pObj.left * pz - previewPaddingHoriz,
    pObj.top * pz - previewPaddingVert
  );

  const hRatio =
    (pObj.width * pz + previewPaddingHoriz) /
    MatgenGlobal.previewEditor.cur().fabric.width;
  const vRatio =
    (pObj.height * pz + previewPaddingVert) /
    MatgenGlobal.previewEditor.cur().fabric.height;
  const ratio = Math.min(hRatio, vRatio);

  MatgenGlobal.previewEditor.cur().fabric.absolutePan(pt);
  MatgenGlobal.previewEditor.cur().fabric.setZoom(ratio);

  MatgenGlobal.previewEditor.cur().fabric.renderAll();

  $('#rich-text-preview-loader').remove();
};

export const richTextEditorPreview = async curObj => {
  richTextPreviewLoader();

  if (!MatgenGlobal.previewEditor) {
    const editor = new MatgenEditor({
      id: `matgen-rt-preview`,
      pageId: MatgenGlobal.editor.curPageId,
      containerId: `matgen-canvas-rt-preview`,
      templateId: MatgenGlobal.editor.templateId,
    });

    MatgenGlobal.previewEditor = editor;
  }

  const template = await MatgenGlobal.Data.getTemplate(
    MatgenGlobal.editor.templateId
  );
  const pageObj = await MatgenGlobal.UI.getPageObject();

  await MatgenGlobal.previewEditor.load({
    json: pageObj,
    template,
    targetSelector: '#rich-text-preview',
    canvasContainerId: `matgen-scale-container-preview`,
    suppressLoader: true,
  });

  $(document).off('matgen-canvas-loaded');
  $(document).on('matgen-canvas-loaded', e => {
    if (e.detail.canvas.id === 'matgen-rt-preview') {
      $('#rich-text-editor .modal-body').css(
        'height',
        $('#rich-text-editor .modal-body').height()
      );
      scaleAndPositionPreview(curObj);
    }
  });
};

function setRichTextColor(e, objId) {
  M4CGlobal.quill[objId].format('color', e.detail.color);
  $('.ql-color-label').css('stroke', e.detail.color);
}

export const RichTextEditor = async curObj => {
  const toolbarOptions = [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    ['color'],
    [{ align: [] }],

    ['clean'],
  ];

  $('#quill-output-wrapper').remove();
  $('body').append(
    $('<div id="quill-output-wrapper"><div id="quill-output" /></div>')
  );

  const newWidth =
    MatgenGlobal.richTextObj.originalWidth *
    MatgenGlobal.richTextObj.originalScaleX;
  const newHeight =
    MatgenGlobal.richTextObj.originalHeight *
    MatgenGlobal.richTextObj.originalScaleY;

  $('#quill-styles').remove();
  $('head').append(`
    <style type="text/css" id="quill-styles">
      #quill-output-wrapper {
        text-align: left;
      }
      #quill-output {
        width: ${newWidth}px;
        height: ${newHeight}px;
        word-wrap: break-word;
        font-family: ${MatgenGlobal.richTextObj.fontFamily};
        font-size: ${MatgenGlobal.richTextObj.fontSize}px;
        font-style: ${MatgenGlobal.richTextObj.fontStyle};
        font-weight: ${MatgenGlobal.richTextObj.fontWeight};
        line-height: ${MatgenGlobal.richTextObj.lineHeight};
        color: ${MatgenGlobal.richTextObj.fill};
      }
      #quill-output ol,
      #quill-output ul {
        padding-left: 1.5em;
      }
      #quill-output p,
      #quill-output ul,
      #quill-output ol,
      #quill-output li {
        word-wrap: break-word;
        font-family: ${MatgenGlobal.richTextObj.fontFamily};
        font-size: ${MatgenGlobal.richTextObj.fontSize}px;
        font-style: ${MatgenGlobal.richTextObj.fontStyle};
        font-weight: ${MatgenGlobal.richTextObj.fontWeight};
        line-height: ${MatgenGlobal.richTextObj.lineHeight};
        color: ${MatgenGlobal.richTextObj.fill};
      }
      #quill-output .ql-align-center {
        text-align: center;
      }
      #quill-output .ql-align-left {
        text-align: left;
      }
      #quill-output .ql-align-right {
        text-align: right;
      }
      #quill-output .ql-align-justify {
        text-align: justify;
      }
    </style>
  `);

  if (!M4CGlobal.quill) {
    M4CGlobal.quill = {};
  }

  M4CGlobal.quill[curObj.id] = new Quill('#inputRichText', {
    modules: {
      toolbar: toolbarOptions,
    },
    theme: 'snow',
  });
  M4CGlobal.quill[curObj.id].focus();

  MatgenGlobal.UI.initColorPicker(
    {
      el: '.ql-color',
      container: '.ql-toolbar',
      default: '#000000',
      useAsButton: true,
      position: 'bottom-middle',
    },
    'rich-text'
  );

  $(document).off('matgen-color-picked');
  $(document).on('matgen-color-picked', e => setRichTextColor(e, curObj.id));

  if (curObj.quillData) {
    M4CGlobal.quill[curObj.id].setContents(JSON.parse(curObj.quillData));
    document.querySelector('#quill-output').innerHTML =
      M4CGlobal.quill[curObj.id].root.innerHTML;
  }

  await richTextEditorPreview(curObj);

  M4CGlobal.quill[curObj.id].on('text-change', () => {
    document.querySelector('#quill-output').innerHTML =
      M4CGlobal.quill[curObj.id].root.innerHTML;
    debouncedrichTextEditorUpdate();
  });

  M4CGlobal.quill[curObj.id].clipboard.addMatcher(
    Node.ELEMENT_NODE,
    (node, delta) => {
      const ops = [];
      delta.ops.forEach(op => {
        if (op.insert && typeof op.insert === 'string') {
          ops.push({
            insert: op.insert,
          });
        }
      });
      delta.ops = ops;
      return delta;
    }
  );

  $('.ql-snow .ql-tooltip input[type=text]').attr('data-lpignore', 'true');
  $('.ql-color-label').css('cssText', 'opacity: 1 !important');
  const Parchment = Quill.import('parchment');

  class CustomColor extends Parchment.Attributor.Style {
    value(node) {
      let value = super.value(node);

      if (!value.startsWith('rgb(')) return value;

      value = value.replace(/^[^\d]+/, '').replace(/[^\d]+$/, '');

      return `#${value
        .split(',')
        .map(component => {
          return `00${parseInt(component, 10).toString(16)}`.slice(-2);
        })
        .join('')}`;
    }
  }

  const customColorAttributor = new CustomColor('custom-color', 'color', {
    scope: Parchment.Scope.BLOCK,
  });

  Quill.register(customColorAttributor);

  const ListItem = Quill.import('formats/list/item');

  class ColoredListItem extends ListItem {
    optimize(context) {
      super.optimize(context);

      if (this.children.length === 1) {
        const child = this.children.head;
        const attributes = child.attributes;

        if (attributes && attributes.attributes.color) {
          const color = attributes.attributes.color.value(child.domNode);
          super.format('custom-color', color);
        }
      } else {
        /* eslint-disable-next-line no-prototype-builtins */
        if (this.attributes.attributes.hasOwnProperty('custom-color')) {
          super.format('custom-color', null);
        }
      }
    }
  }

  Quill.register(ColoredListItem, true);
};
