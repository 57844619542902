/* global MatgenGlobal, PDFDocument, blobStream, Buffer */

import { pt, traverse } from '../../matgen-ui/common/helpers.js';
import MatgenUIFunctions from '../../matgen-ui/ui-functions.js';

class MatgenPDF {
  constructor({ width, height, name }) {
    this.doc = new PDFDocument({
      size: [pt(width), pt(height)],
      pdfVersion: '1.7',
      tagged: true,
      displayTitle: true,
      info: {
        Title: name,
      },
      lang: 'en-US',
      margins: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    });

    this.stream = this.doc.pipe(blobStream());
    this.docStruct = this.doc.struct('Document');
    this.doc.addStructure(this.docStruct);

    this.curPage = 0;
    this.pages = [];
  }

  static handlePDFError() {
    console.error('handlePDFError');
    MatgenGlobal.UI.handleError(
      'PDF Generation Error',
      'There was an error generating the PDF. Missing or corrupted data in material files.'
    );
    return false;
  }

  static async createPDF(
    template,
    material,
    pageData,
    id,
    type,
    non508 = true,
    overlay = true
  ) {
    const pdf = new MatgenPDF({
      width: template.width,
      height: template.height,
      name: material ? material.name : template.name,
    });

    pdf.addPage();

    let genError = false;

    for (let i = 0; i < pageData.length; i++) {
      const pdfObjects = [];
      MatgenGlobal.TempEditor = await MatgenGlobal.MatgenPageLoader.start({
        message: `Loading page ${i + 1}`,
        promise: MatgenUIFunctions.loadPagePreview(
          template,
          pageData[i].id,
          id,
          type,
          true,
          true
        ),
      });

      if (!MatgenGlobal.TempEditor) {
        return MatgenPDF.handlePDFError();
      }
      MatgenGlobal.TempEditor.cur().fabric.backgroundColor = 'white';
      if (non508 === false) {
        const objects = MatgenGlobal.TempEditor.cur()
          .fabric.getObjects()
          .filter(o => o.visible)
          .map(o => MatgenUIFunctions.getReadOrder(o));

        objects.sort(MatgenUIFunctions.readOrderSort);

        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Creating PDF objects',
          promise: traverse(objects, async o => {
            try {
              const obj = await MatgenGlobal.MatgenPageLoader.start({
                message: 'Creating PDF object',
                promise: MatgenPDF.getPDFObject(o, pdf.doc),
              });
              if (obj) {
                pdfObjects.push(obj);
              }
            } catch (e) {
              console.error(e);
              genError = true;
            }
          }),
        });

        pdfObjects.sort(MatgenUIFunctions.readOrderSort);
        for (let i = 0; i < pdfObjects.length; i++) {
          if (pdfObjects[i].version) {
            pdf.addArtifact(pdfObjects[i]);
          } else {
            pdf.addStructure(pdfObjects[i]);
          }
        }
      }

      if (non508 || overlay) {
        const pageImage = MatgenUIFunctions.getCanvasDataURL(
          null,
          MatgenGlobal.TempEditor
        );
        pdf.addArtifact({
          src: pageImage,
          left: 0,
          top: 0,
          width: template.width,
          height: template.height,
          scaleX: 1,
          scaleY: 1,
        });
      }
      pdf.pages[pdf.curPage - 1].end();

      if (pdf.curPage < pageData.length) {
        pdf.addPage();
      }
    }

    pdf.stream.on('finish', () => {
      if (!genError) {
        window.setTimeout(() => {
          MatgenGlobal.emit({
            event: 'download-pdf-generated',
          });
          MatgenGlobal.UI.confirm(
            'Download PDF',
            'Your PDF will open in a new window.',
            'Continue',
            'Cancel',
            () => {
              window.open(pdf.stream.toBlobURL('application/pdf'));
            }
          );
        }, 1250);
      } else {
        return MatgenPDF.handlePDFError();
      }

      delete MatgenGlobal.generating;
      delete MatgenGlobal.TempEditor;
    });

    pdf.doc.end();
  }

  addPage() {
    if (this.curPage > 0) {
      this.doc.addPage();
    }
    this.pages.push(this.doc.struct('Sect'));
    this.docStruct.add(this.pages[this.curPage]);
    this.curPage++;
  }

  addArtifact(obj) {
    if (!obj) {
      console.error('Invalid artifact object');
      return false;
    }
    if (
      !['rect', 'circle', 'polygon', 'line', 'ellipse', 'triangle'].includes(
        obj.type
      )
    ) {
      this.doc.markContent('Artifact', { type: 'Layout' });
      this.doc.image(obj.src, pt(obj.left), pt(obj.top), {
        width: pt(obj.width * obj.scaleX),
        height: pt(obj.height * obj.scaleY),
      });
      this.doc.endMarkedContent();
    }
  }

  addStructure(obj) {
    this.pages[this.curPage - 1].add(obj.struct);
  }

  static async loadFont(spec) {
    const url = `https://2i9mwjmz45.execute-api.us-east-1.amazonaws.com/${
      MatgenGlobal.AMPLIFY_VARS.apiStage
    }/fonts?family=${spec.replace(/\s+/g, '+')}`;
    let res, file;

    try {
      res = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading font list',
        promise: fetch(url),
      });
      file = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading font list text',
        promise: res.text(),
      });
    } catch (e) {
      console.error(e);
      return false;
    }

    let ab;
    try {
      res = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading font',
        promise: fetch(file),
      });
      ab = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Buffering font',
        promise: res.arrayBuffer(),
      });
    } catch (e) {
      console.error(e);
      return false;
    }
    return ab;
  }

  static async getPDFObject(o, doc) {
    let left, top, fill, opacity, width, align;
    let tag = o.pdfTag ? o.pdfTag : o.componentPdfTag;
    let link = null;
    if (!tag) {
      if (o.type === 'image' && !o.richText) {
        tag = 'FIGURE';
      }
      if (o.type === 'image' && o.richText) {
        tag = 'P';
      }
      if (o.type === 'textbox') {
        tag = 'P';
      }
    }
    if (tag === 'FIGURE' && (!o.altText || o.altText === '')) {
      tag = 'ARTIFACT';
    }
    if (tag === 'ARTIFACT') {
      return o;
    }
    if (tag === 'A') {
      tag = 'Link';
    }
    switch (o.type) {
      case 'image':
        return {
          struct: doc.struct(
            tag,
            {
              alt: o.altText,
            },
            () => {
              doc.image(o.src, pt(o.left), pt(o.top), {
                width: pt(o.width * o.scaleX),
                height: pt(o.height * o.scaleY),
              });
            }
          ),
          ro1: o.ro1,
          ro2: o.ro2,
        };
      case 'textbox':
        if (o.fontspec && o.fontType !== 'local') {
          try {
            const font = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading font',
              promise: MatgenPDF.loadFont(o.fontspec),
            });
            if (font) {
              doc.registerFont(o.fontspec, new Buffer(font));
            }
          } catch (e) {
            console.error(e);
          }
        }

        left = pt(o.left);
        top = pt(o.top);
        width = pt(o.width * o.scaleX);
        align = o.textAlign;
        if (o.group) {
          left = o.group.left;
          top += pt(o.group.top) + pt((o.group.height * o.group.scaleY) / 2);
          width = pt(o.group.width * o.group.scaleX);
        }
        if (o.text) {
          o.text = o.text.replace(/^\s+|\s+$/g, '');
          o.text = o.text.replace(/(\r?\n){2,}/g, '$1');
        }
        if (o.plainText) {
          o.plainText = o.plainText.replace(/^\s+|\s+$/g, '');
          o.plainText = o.plainText.replace(/(\r?\n){2,}/g, '$1');
        }
        fill = o.fill;
        opacity = 1;

        if (o.fill.length > 7) {
          fill = o.fill.substring(0, 7);
          opacity = parseInt(o.fill.substring(7), 16) / 255.0;
        }

        return {
          struct: doc.struct(tag, () => {
            const text = o.plainText ? o.plainText : o.text;
            if (text.match(/\r?\n/g)) {
              const lines = text.split(/\r?\n/);
              const first = lines.shift();
              if (o.fontType !== 'local') {
                doc.font(o.fontspec);
              }
              doc
                .fontSize(pt(o.fontSize * o.scaleX))
                .fillColor(fill)
                .opacity(opacity)
                .text(`${first} `, left, top, { width, align });
              lines.forEach(l => {
                if (tag === 'Link') {
                  if (o.link) {
                    link = o.link;
                  } else {
                    if (l.includes('http')) {
                      link = l;
                    } else {
                      link = `https://${l}`;
                    }
                  }
                }
                doc.text(`${l} `, { width, align, link });
              });
            } else {
              if (tag === 'Link') {
                if (o.link) {
                  link = o.link;
                } else {
                  if (o.text.includes('http')) {
                    link = o.text;
                  } else {
                    link = `https://${o.text}`;
                  }
                }
              }

              if (o.fontType !== 'local') {
                doc.font(o.fontspec);
              }
              if (o.text === '[Study ID]') {
                top = 1634;
              }
              doc
                .fontSize(pt(o.fontSize * o.scaleX))
                .fillColor(fill)
                .opacity(opacity)
                .text(`${o.text} `, left, top, {
                  width,
                  align,
                  link,
                });
            }
          }),
          ro1: o.ro1,
          ro2: o.ro2,
        };
      default:
        break;
    }
  }
}

export { MatgenPDF };
