import * as MatgenForms from '../forms';

class SignupForm {
  constructor() {
    const inputs = [
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Email',
          id: 'm4c-signup-email',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Confirm Email',
          id: 'm4c-signup-email-confirm',
        },
      },
      {
        component: 'RawHTML',
        html: `
          <div class="m-3 signup-form-password-rules">Password must be at least 8 characters long and contain at least one number, symbol, and uppercase letter.</div>
          `,
      },
      {
        component: 'Text',
        options: {
          type: 'password',
          label: 'Password',
          id: 'm4c-signup-password',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'password',
          label: 'Confirm Password',
          id: 'm4c-signup-password-confirm',
        },
      },
    ];

    return new MatgenForms.DynamicForm(inputs, [], 'm4c-signup-form');
  }
}
export { SignupForm };
