import * as Form from '../form/index.js';

class DynamicForm {
  constructor(inputs, buttons, id) {
    // Async dependencies - make constructor async
    // by returning a self-executing anonymous function
    return (async () => {
      try {
        const DynamicInputs = await Promise.all(
          inputs.map(i => {
            if (i.component === 'RawHTML') {
              return i.html;
            }
            //console.error(Form[`${i.component}Input`]);
            return new Form[`${i.component}Input`](i.options);
          })
        );
        this.form = new Form.Form({
          inputs: DynamicInputs,
          id,
        });

        return this; // Return the newly-created instance
      } catch (e) {
        console.error(Error(e));
        return false;
      }
    }).call(this); // Execute with "this" context
  }
}

export { DynamicForm };
