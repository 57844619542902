import { Input } from './Input.js';

class TextInput extends Input {
  constructor() {
    super(...arguments);
  }
  getHTML() {
    return `
      <div class="row">
        <label for="${this.id}" class="col-5 col-form-label">${
      this.label
    }</label>
        <div class="col-7">
          <input
            type="${this.type}"
            id="${this.id}"
            name="${this.id}"
            class="form-control ${this.classes}"
            placeholder="${this.label}"
            ${this.required ? 'required' : ''}
            ${
              this.type === 'tel'
                ? 'pattern="[^a-zA-Z0-9_\n]{0,2}[0-9]{0,3}[^a-zA-Z0-9_\n]{0,2}[0-9]{0,3}[^a-zA-Z0-9_\n]{0,2}[0-9]{0,3}[^a-zA-Z0-9_\n]{0,2}[0-9]{0,4}\n?"'
                : ''
            }
            ${this.autofocus ? 'autofocus' : ''}
            ${this.value ? `value="${this.value}"` : ''}
          />
        </div>
        ${this.helpText ? `<div class="form-text">${this.helpText}</div>` : ''}
      </div>
      `;
  }
}

export { TextInput };
