import { Form, TextInput, CheckboxInput } from '../form/index.js';
//import { emit } from '../../matgen-ui/common/helpers.js';

const TenantInputs = [
  new TextInput({
    type: 'text',
    label: 'Name',
    id: 'inputName',
    required: true,
    autofocus: true,
  }),
  new CheckboxInput({
    label: '508 Compliant',
    id: 'InputIs508',
  }),
];

const TenantForm = new Form({
  inputs: TenantInputs,
  id: 'tenant-form',
  title: 'Create/Edit Tenant',
});

export { TenantForm };
