/* global $ */

import * as MatgenForms from '../forms';

class AnswerJSONForm {
  constructor({ question_id = null, answer = null } = {}) {
    const inputs = [
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Answer Text',
          id: 'inputText',
          required: true,
          value: answer && answer.text ? answer.text.replace(/\"/g, '&quot;') : '',
        },
      },
      {
        component: 'RawHTML',
        html: `
            <input type="hidden" id="q-question-id" name="q-question-id" value="${
              question_id
                ? question_id
                : answer.question_id
                ? answer.question_id
                : ''
            }"/>
            `,
      },
    ];

    return new MatgenForms.DynamicForm(inputs, [], 'create-answer-form');
  }
}
export { AnswerJSONForm };

// questionnaire form xxx
// answer page
// edit answers
