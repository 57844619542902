/* global M4CGlobal, MatgenGlobal, $, bootstrap */

import { basics, create_template, add_items } from './help-content.js';
import { RichTextForm } from '../../../m4c-form/StaticForms/RichTextForm.js';
import {
  loadRichTextFonts,
  richTextEditorPreview,
  debouncedrichTextEditorUpdate,
} from '../RichTextEditor.js';
import M4CRichTextEditor from '../M4CRichTextEditor.js';

class SidebarHeader {
  static sidebarHeader(template, pages) {
    const actions = [];
    if (MatgenGlobal.AuthUser.getUserRole() === 'super') {
      actions.push(
        ['reference-image', 'Upload a reference image', `fas fa-image`],
        ['show-grid', 'Enable grid/snapping', `fas fa-border-all`],
        ['theme-color', 'Set Theme Color', `far fa-palette`]
      );
    }
    if (
      MatgenGlobal.AuthUser.isM4CSuper() ||
      MatgenGlobal.AuthUser.getUserRole() === 'admin'
    ) {
      actions.push([
        'template-tag',
        'Manage template tags',
        [`fas fa-tags`],
        template.id,
      ]);
    }
    return `
      <li href="#" id="sidebar-header" class="list-group-item active d-flex flex-row sidebar-li">
        <div id="sidebar-title" style="display:none;">Editable Items</div>
        <div class="sidebar-actions">

          ${
            MatgenGlobal.AuthUser.isM4CSuper()
              ? SidebarHeader.helpDropHTML(pages)
              : ''
          }
          ${actions.map(a => SidebarHeader.headerActionHTML(...a)).join('')}

          ${
            MatgenGlobal.AuthUser.isM4CSuper() ||
            MatgenGlobal.AuthUser.getUserRole() === 'admin'
              ? SidebarHeader.pagesDropHTML(pages)
              : ''
          }
          ${
            MatgenGlobal.AuthUser.isM4CSuper() ||
            MatgenGlobal.AuthUser.getUserRole() === 'admin'
              ? SidebarHeader.headerActionHTML(
                  'template-save',
                  'Save template',
                  [`fas fa-save`, `fa fa-exclamation dirty`],
                  template.id
                )
              : ''
          }

          ${
            MatgenGlobal.AuthUser.isM4CSuper()
              ? `
            <div class="dropdown" data-bs-toggle="tooltip" data-placement="auto" title="Canvas Zoom">
              <button id="template-zoom" type="button" class="btn btn-sm btn-secondary"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Canvas Zoom">
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
              <div id="template-zoom-dropdown"class="dropdown-menu" aria-labelledby="template-zoom">
                <a class="dropdown-item" id="zoom-default" href="#">Default/auto</a>
                <a class="dropdown-item" id="zoom-fit" href="#">Fit to viewport</a>
              </div>
            </div>
            `
              : ''
          }

          ${
            MatgenGlobal.AuthUser.isM4CSuper()
              ? SidebarHeader.headerActionHTML(
                  'template-add-item',
                  'Add item',
                  [`fa-solid fa-circle-plus`]
                )
              : ''
          }
        </div>
      </li>
      `;
  }

  static headerActionHTML(id, title, icon, dataId = false) {
    const icons = SidebarHeader.buildIconArray(icon);
    return `
      <button id="${id}" ${
      dataId !== false ? `data-id="${dataId}"` : ''
    } type="button" class="btn btn-sm btn-secondary" data-bs-toggle="tooltip" data-placement="auto" title="${title}">
        ${icons.map(i => `<i class="${i}"></i>`).join('')}
      </button>
      `;
  }

  static buildIconArray(icon) {
    let icons = [];
    if (!Array.isArray(icon)) {
      icons.push(icon);
    } else {
      icons = icon;
    }
    return icons;
  }

  static helpDropHTML(pages) {
    return SidebarHeader.headerDropDownHTML({
      id: 'editor-help',
      title: 'Help',
      icon: `fa-solid fa-circle-question`,
      badge: false,
      items: [
        {
          id: 'help-basics',
          title: 'Matgen basics and terms',
          icon: `fa-solid fa-code-simple`,
          classes: ['matgen-help'],
        },
      ],
      pages,
    });
  }

  static downloadsDropHTML(id, pages) {
    return SidebarHeader.headerDropDownHTML({
      id: 'template-download',
      title: 'Template Download',
      icon: `fa-solid fa-folder-arrow-down`,
      badge: false,
      items: [
        {
          id: 'template-download',
          title: 'Download template',
          icon: `fa-solid fa-folder-arrow-down`,
          classes: ['template-download'],
          dataId: id,
        },
      ],
      pages,
    });
  }

  static headerDropDownHTML({
    id,
    title,
    icon,
    badge,
    items,
    classes = [],
    pages,
  }) {
    const iconHTML = SidebarHeader.badgedIcon(icon, badge);
    return `
    <div id="${id}-div" class="dropdown"">
      <button class="btn btn-sm btn-secondary ${classes.join(
        ' '
      )}" id="${id}-btn" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span data-bs-toggle="tooltip" data-placement="auto" title="${title}">
        ${iconHTML}
      </span>
      </button>
      <div id="${id}-dropdown"class="dropdown-menu" aria-labelledby="${id}-btn">
        ${items
          .map(i => {
            if (!i.role || i.role === MatgenGlobal.AuthUser.getUserRole()) {
              const icons = SidebarHeader.buildIconArray(i.icon);
              return `
                <a id="${i.id}" ${
                i.dataId ? `data-id="${i.dataId}"` : ''
              } class="dropdown-item ${i.classes.join(' ')} ${i.id}" href="#">
                  ${icons.map(i => `<i class="${i}"></i>`).join('')}
                  ${i.title} ${
                MatgenGlobal.AuthUser.isM4CSuper() &&
                i.delete &&
                pages.length > 1
                  ? `<span class="drop-delete text-danger"><i class="${i.delete}"></i></span>`
                  : ''
              }
                </a>
              `;
            } else {
              return '';
            }
          })
          .join('')}
      </div>
    </div>
    `;
  }

  static pagesDropHTML(pages) {
    let items = [];
    if (pages.length > 0) {
      items = pages.map((p, i) => {
        return {
          id: `page-${i}`,
          dataId: p.id,
          title: `Page ${p.number + 1}`,
          icon: `fa-solid fa-page`,
          classes: ['template-page-edit', `page-${i}`],
          delete: `fa-solid fa-file-xmark`,
        };
      });
    }
    return SidebarHeader.headerDropDownHTML({
      id: 'template-pages',
      title: 'Template Pages',
      icon: `fa-solid fa-file-circle-info`,
      badge: {
        count: pages.length,
        classes: ['btn-badge', 'header'],
      },
      items: [
        ...items,
        {
          id: 'template-add-page',
          title: 'Add page',
          icon: `fa-solid fa-plus`,
          classes: ['template-add-page'],
          role: 'super',
        },
      ],
      pages,
    });
  }

  static badgedIcon(icon, badge) {
    const icons = SidebarHeader.buildIconArray(icon);
    let html = icons.map(i => `<i class="${i}"></i>`).join('');
    if (badge) {
      html += `<span class="badge ${
        badge.classes ? badge.classes.join(' ') : ''
      }">${badge.count}</span>`;
    }
    return html;
  }

  static shapesModal() {
    const cards = [
      {
        id: 'polygon',
        title: 'Polygon',
        icon: '<i class="fa-solid fa-draw-polygon fa-2x"></i>',
        text:
          'Select this option to draw a polygon element. <i>(Use ESC key to cancel drawing.)</i>',
      },
      {
        id: 'circle',
        title: 'Circle',
        icon: '<i class="fa-solid fa-circle fa-2x"></i>',
        text: 'Select this option to add a circle element.',
      },
      {
        id: 'ellipse',
        title: 'Ellipse',
        icon: '<i class="fa-solid fa-vector-circle fa-2x"></i>',
        text: 'Select this option to add an ellipse element.',
      },
      {
        id: 'rectangle',
        title: 'Rectangle',
        icon: '<i class="fa-solid fa-rectangle fa-2x"></i>',
        text: 'Select this option to add a rectangle or square element.',
      },
      {
        id: 'triangle',
        title: 'Triangle',
        icon: '<i class="fa-solid fa-triangle fa-2x"></i>',
        text: 'Select this option to add a triangle element.',
      },
    ];
    bootstrap.Modal.getOrCreateInstance($(`#add-item-modal`)[0]).hide();
    SidebarHeader.cardsModal(
      'add-shape',
      'Add shape',
      'Select shape type',
      cards
    );
    $('#add-shape-modal .modal-body').prepend(`
      <div class="d-flex">
        <input type="hidden" id="inputShapeColor" />
        <div class="color-picker-form form-control middle">Shape Color: <span id="shape-color-picker" class="color-picker"></span></div>
        <div class="form-control middle">Shape Stroke Width:
          <input type="text" id="inputShapeStrokeWidth" style="height:max-content" value="0" />
        </div>
        <input type="hidden" id="inputShapeStrokeColor" />
        <div class="color-picker-form form-control middle">Shape Stroke Color: <span id="shape-stroke-color-picker" class="color-picker"></span></div>
      </div>
      <div class="form-control middle">

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" id="inputUseThemeColorShape">
        <label class="form-check-label" for="inputUseThemeColorShape">Use Theme Color</label>
        <div id="themeColorShapeOpacityContainer" class="align-items-center" style="display:none;">
          <label for="themeColorShapeOpacity" class="form-label visually-hidden">Theme Color Opacity</label>
          <input type="range" min="0" max="255" value="255" class="form-range" id="themeColorShapeOpacity">
          <input type="text" class="form-control" id="themeColorShapeOpacityNumber" data-lpignore="true" />
        </div>
      </div>

      <br />
    `);

    MatgenGlobal.UI.initColorPicker(
      {
        el: '#shape-color-picker',
        container: '#add-shape-modal',
        default: '#74BC1E',
      },
      'shape',
      '#inputShapeColor'
    );

    MatgenGlobal.UI.initColorPicker(
      {
        el: '#shape-stroke-color-picker',
        container: '#add-shape-modal',
        default: '#00000000',
      },
      'shapeStroke',
      '#inputShapeStrokeColor'
    );
  }

  static async richTextModal(creating = false) {
    MatgenGlobal.richTextObj = MatgenGlobal.editor
      .cur()
      .fabric.getActiveObject();

    if (creating) {
      MatgenGlobal.richTextObj.originalWidth = MatgenGlobal.richTextObj.width;
      MatgenGlobal.richTextObj.originalHeight = MatgenGlobal.richTextObj.height;
      MatgenGlobal.richTextObj.originalTop = MatgenGlobal.richTextObj.top;
      MatgenGlobal.richTextObj.originalLeft = MatgenGlobal.richTextObj.left;
      MatgenGlobal.richTextObj.originalScaleX = MatgenGlobal.richTextObj.scaleX;
      MatgenGlobal.richTextObj.originalScaleY = MatgenGlobal.richTextObj.scaleY;
    }

    const modalId = 'rich-text-editor';
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: 'Add/Edit Rich Text',
      content: RichTextForm(),
      buttons: [
        {
          id: 'rich-text-editor-submit',
          classname: 'primary btn btn-primary',
          label: 'Done',
          attributes: ['data-rte-id="edit-rich-text"'],
        },
      ],
    });

    const modal = document.getElementById('rich-text-editor');
    modal.addEventListener('hidden.bs.modal', () => {
      $('#quill-output-wrapper').remove();
      $(document).off('matgen-canvas-loaded');
    });

    await loadRichTextFonts();

    window.setTimeout(() => {
      new M4CRichTextEditor({
        minHeight: '24em',
        targetSelector: '#inputRichText',
        id: 'edit-rich-text',
      });
      $('#quill-output-wrapper').remove();
      $('body').append(
        $('<div id="quill-output-wrapper"><div id="quill-output" /></div>')
      );

      M4CGlobal.quill['edit-rich-text'].on('text-change', () => {
        document.querySelector('#quill-output').innerHTML =
          M4CGlobal.quill['edit-rich-text'].root.innerHTML;
        debouncedrichTextEditorUpdate({ id: 'edit-rich-text' });
      });

      const newWidth =
        MatgenGlobal.richTextObj.originalWidth *
        MatgenGlobal.richTextObj.originalScaleX;
      const newHeight =
        MatgenGlobal.richTextObj.originalHeight *
        MatgenGlobal.richTextObj.originalScaleY;

      $('#quill-styles').remove();
      $('head').append(`
      <style type="text/css" id="quill-styles">
        #quill-output-wrapper {
          text-align: left;
        }
        #quill-output {
          width: ${newWidth}px;
          height: ${newHeight}px;
          word-wrap: break-word;
          font-family: ${MatgenGlobal.richTextObj.fontFamily};
          font-size: ${MatgenGlobal.richTextObj.fontSize}px;
          font-style: ${MatgenGlobal.richTextObj.fontStyle};
          font-weight: ${MatgenGlobal.richTextObj.fontWeight};
          line-height: ${MatgenGlobal.richTextObj.lineHeight};
          color: ${MatgenGlobal.richTextObj.fill};
        }
        #quill-output ol,
        #quill-output ul {
          padding-left: 1.5em;
        }
        #quill-output p,
        #quill-output ul,
        #quill-output ol,
        #quill-output li {
          word-wrap: break-word;
          font-family: ${MatgenGlobal.richTextObj.fontFamily};
          font-size: ${MatgenGlobal.richTextObj.fontSize}px;
          font-style: ${MatgenGlobal.richTextObj.fontStyle};
          font-weight: ${MatgenGlobal.richTextObj.fontWeight};
          line-height: ${MatgenGlobal.richTextObj.lineHeight};
          color: ${MatgenGlobal.richTextObj.fill};
        }
        #quill-output .ql-align-center {
          text-align: center;
        }
        #quill-output .ql-align-left {
          text-align: left;
        }
        #quill-output .ql-align-right {
          text-align: right;
        }
        #quill-output .ql-align-justify {
          text-align: justify;
        }
      </style>
    `);

      richTextEditorPreview(MatgenGlobal.richTextObj);
      if (MatgenGlobal.richTextObj.quillData) {
        M4CGlobal.quill['edit-rich-text'].setContents(
          JSON.parse(MatgenGlobal.richTextObj.quillData)
        );
        document.querySelector('#quill-output').innerHTML =
          M4CGlobal.quill['edit-rich-text'].root.innerHTML;
      }
      //RichTextEditor(M4CGlobal.richTextObj);
    }, 1000);
  }

  static cardsModal(id, title, subtitle, cards) {
    SidebarHeader.simpleModal(
      id,
      title,
      `
      <h5 style="text-align:center;margin-bottom:.75em;">${subtitle}</h5>
      <div class="card-group container">
      ${cards.map(c => SidebarHeader.cardHTML(c)).join('')}
      </div>
      `
    );
  }

  static cardHTML(card) {
    return `
    <div class="card ${card.disabled === true ? 'disabled' : ''}" id="${
      card.id
    }">
      <div class="card-body">
        <h5 class="card-title">
          ${card.icon}
          <div class="title-text">${card.title}</div>
        </h5>
        <div>
          <p class="card-text">${card.text}</p>
        </div>
      </div>
    </div>
    `;
  }

  static simpleModal(id, title, content, buttonText = 'Close') {
    MatgenGlobal.M4CModal.show({
      id: `${id}-modal`,
      title: title,
      content,
      buttons: [
        {
          id: `${id}-close-button`,
          classname: 'primary btn btn-primary',
          label: buttonText,
        },
      ],
      width: 'fit-content',
    });

    $(`#${id}-close-button`).attr('data-bs-dismiss', 'modal');
  }

  static modal508Content() {
    const objects = MatgenGlobal.editor
      .cur()
      .fabric.getObjects()
      .filter(o => o.visible)
      .map(o => MatgenGlobal.MatgenUIFunctions.getReadOrder(o));

    objects.sort(MatgenGlobal.MatgenUIFunctions.readOrderSort);
    return basics;
  }

  static modal508(id) {
    SidebarHeader.simpleModal(
      '508',
      '508 Compliance',
      SidebarHeader.modal508Content(id),
      'Done'
    );
  }

  static helpModal(title, content) {
    SidebarHeader.simpleModal('help', title, content);
  }

  static helpPage(id) {
    let title, content;
    switch (id) {
      default:
      case 'help-basics':
        title = 'Matgen basics and terms';
        content = basics;
        break;
      case 'help-create-template':
        title = 'Creating templates';
        content = create_template;
        break;
      case 'help-add-items':
        title = 'Creating template images and text';
        content = add_items;
        break;
    }
    return SidebarHeader.helpModal(title, content);
  }

  static scaleVar(x) {
    const value = parseInt(x);
    const zoom = MatgenGlobal.editor.cur().fabric.getZoom();
    const scale = MatgenGlobal.richTextObj.scaleX;
    return value * zoom * scale;
  }
}

export { SidebarHeader };
