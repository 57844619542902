/* global M4CGlobal, $ */

import merge from 'deepmerge';
import Pickr from '@simonwep/pickr';

if (typeof M4CGlobal === 'undefined') {
  window.M4CGlobal = {};
}
if (!M4CGlobal.pickr) {
  M4CGlobal.pickr = [];
}

Pickr.prototype.getSwatches = function() {
  return this._swatchColors.reduce((arr, swatch) => {
    arr.push(swatch.color.toRGBA().toString(0));
    return arr;
  }, []);
};

// Set Swatches
Pickr.prototype.setSwatches = function(swatches) {
  if (!swatches.length) return;
  for (let i = this._swatchColors.length - 1; i > -1; i--) {
    this.removeSwatch(i);
  }
  swatches.forEach(swatch => this.addSwatch(swatch));
};

const M4CColorPicker = (config, id, inputSelector = '#inputColorPicker') => {
  let recent = sessionStorage.getItem('recentColors');
  if (recent) {
    recent = JSON.parse(recent);
  }
  //console.error('RECENT SWATCHES:', recent);
  const defaults = {
    el: '#color-picker',
    container: '#color-picker-container',
    theme: 'classic', // or 'monolith', or 'nano'
    default: '#000000',
    swatches: recent ? recent : false,
    position: 'bottom-middle',
    components: {
      preview: true,
      opacity: true,
      hue: true,
      interaction: {
        hex: true,
        rgba: true,
        hsla: true,
        hsva: true,
        cmyk: true,
        input: true,
        save: true,
      },
    },
  };

  const initConfig = merge(defaults, config);

  M4CGlobal.pickr[id] = Pickr.create(initConfig);

  M4CGlobal.pickr[id].on('save', () => {
    M4CGlobal.pickr[id].hide();
    let recent = sessionStorage.getItem('recentColors');
    const color = M4CGlobal.pickr[id]
      .getColor()
      .toHEXA()
      .toString();
    if (recent) {
      recent = JSON.parse(recent);
      if (!recent.includes(color)) {
        recent.push(color);
        if (recent.length > 15) {
          recent.shift();
        }
        //M4CGlobal.pickr[id].addSwatch(color);
      }
    } else {
      recent = [];
      recent.push(color);
      //M4CGlobal.pickr[id].addSwatch(color);
    }
    recent = [...new Set(recent)];
    sessionStorage.setItem('recentColors', JSON.stringify(recent));

    // Remove all existing swatches
    for (let j = M4CGlobal.pickr[id].getSwatches().length; j >= 0; j--) {
      M4CGlobal.pickr[id].removeSwatch(j);
    }
    // Then add predefined swatches
    M4CGlobal.pickr[id].setSwatches(recent);

    $(inputSelector).val(
      M4CGlobal.pickr[id]
        .getColor()
        .toHEXA()
        .toString()
    );
    document.dispatchEvent(
      new CustomEvent('matgen-color-picked', {
        detail: {
          id,
          color: M4CGlobal.pickr[id]
            .getColor()
            .toHEXA()
            .toString(),
        },
      })
    );
  });
};

export default M4CColorPicker;
