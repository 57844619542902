/* global $, MatgenGlobal */

import * as MatgenForms from '../forms';

class ExampleForm {
  constructor({question_id = null, example = null} = {}) {
    const inputs = [
      {
        component: 'Select',
        options: {
          label: 'Example Type',
          id: 'inputType',
          classes: 'middle',
          required: true,
          options: [
            {
              label: 'Textarea',
              value: 'textarea',
            },
            {
              label: 'Rich Text',
              value: 'richtext',
            },
          ],
        },
      },
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Example Text',
            id: 'inputText',
            required: false,
            value: (example && example.name) ? example.name : '',
          },
        },
        {
          component: 'RawHTML',
          html: `
            <div id="inputRichText" class=""></div>
            `,
        },
        {
          component: 'RawHTML',
          html: `
            <input type="hidden" id="q-question-id" name="q-question-id" value="${question_id ? question_id : example.question_id ? example.question_id : ''}"/>
            `,
        },
      ];

    return new MatgenForms.DynamicForm(
        inputs,
        [],
        'create-example-form'
      );
  }
}
export { ExampleForm };