/* global $, MatgenGlobal */

function setFormRef(scope, funcRef) {
  MatgenGlobal.DynamicForms[scope.id] = scope;
  $(document).off('matgen-ready', funcRef);
}

class Form {
  constructor({
    inputs = [],
    buttons = [],
    id = false,
    autocomplete = false,
    title = false,
    topContent = false,
    submit = e => console.log(e),
    bottomContent = false,
  } = {}) {
    if (!id) {
      console.error('Form requires an id');
      return false;
    }
    this.inputs = inputs;
    this.buttons = buttons;
    this.id = id;
    this.autocomplete = autocomplete;
    this.title = title;
    this.topContent = topContent;
    this.submit = submit;
    this.bottomContent = bottomContent;

    if (!window.MatgenGlobal || !MatgenGlobal.DynamicForms) {
      $(document).on('matgen-ready', e => setFormRef(this, e));
    } else {
      MatgenGlobal.DynamicForms[this.id] = this;
    }
  }

  async getElement(opts) {
    try {
      const content = await this.getHTML(opts);
      return $(content);
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getHTML({ inline = true, hide = [] } = {}) {
    const inputs = await Promise.all(
      this.inputs.map(i =>
        hide.includes(i.dataId) ? '' : i.getHTML ? i.getHTML({ inline }) : i
      )
    );
    const buttons = await Promise.all(
      this.buttons.map(i =>
        hide.includes(i.dataId) ? '' : i.getHTML ? i.getHTML({ inline }) : i
      )
    );
    return `
      <div id="form-container" class="container-fluid">
        <form${this.id ? ` id="${this.id}"` : ''} class="matgen-form"${
      this.autocomplete ? ` autocomplete="${this.autocomplete}"` : ''
    }>
          ${this.title ? `<h1 class="h3 mb-3">${this.title}</h1>` : ''}
          <div class="form-error"></div>
          <div class="form-success"></div>
          ${this.topContent ? this.topContent : ''}
          ${inputs.join('')}
          ${buttons.join('')}
          ${this.bottomContent ? this.bottomContent : ''}
        </form>
      </div>
    `;
  }

  setValues(values) {
    Object.keys(values).forEach(k => {
      const input = this.inputs.find(
        i => i.dataId === k || i.id === k || i.name === k
      );
      if (input) {
        input.value = values[k];
      }
    });
  }

  getValues() {
    return this.inputs
      .filter(i => i.id)
      .map(i => {
        return {
          id: i.id,
          value:
            i.type !== 'checkbox'
              ? $(`#${i.id}`).val()
              : $(`#${i.id}`)[0].checked,
        };
      });
  }

  validate() {
    const form = document.getElementById(this.id);
    if (!form.checkValidity()) {
      if (form.reportValidity) {
        form.reportValidity();
      } else {
        MatgenGlobal.UI.alertModal(
          'Form input error',
          `
              <div class="alert alert-danger" role="alert">
                <i class="fas fa-bomb"></i> There are errors in the form, please check your input and try again.
              </div>
            `
        );
      }
      return false;
    }
    return true;
  }

  reset() {
    this.inputs.forEach(i => {
      delete i.value;
    });
  }
}

export { Form };
