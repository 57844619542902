export const basics = `
  <h1>Keyboard Shortcuts:</h1>
  <p><i>(We're using cmd for brevity - on windows it's ctrl)</i></p>
  <ul>
    <li><b>Zoom in:</b> cmd + i</li>
    <li><b>Zoom out:</b> cmd + o</li>
    <li><b>Zoom reset:</b> cmd + shift + 0</li>
    <li><b>Copy:</b> cmd + c</li>
    <li><b>Paste:</b> cmd + v</li>
    <li><b>Align items to shared left:</b> cmd + shift + l</li>
    <li><b>Align items to shared center:</b> cmd + shift + c</li>
    <li><b>Align items to shared right:</b> cmd + shift + r</li>
    <li><b>Send backward:</b> cmd + shift + b</li>
    <li><b>Bring forward:</b> cmd + shift + f</li>
  </ul>
`;

export const create_template = `
<style>
/*
LINKS:
Typetura docs: https://docs.typetura.com/creating-with-typetura/creating-your-own-typographic-system
Cubic Bezier: https://cubic-bezier.com/
Fraunces: https://fraunces.undercase.xyz/
Google variable fonts: https://css-tricks.com/getting-the-most-out-of-variable-fonts-on-google-fonts/
Grid system: https://gist.github.com/scottkellum/bb46ea68c23e630842ee80c37513a3e6
*/

/* Import our variable font Fraunces */
@import url("https://fonts.googleapis.com/css2?family=Fraunces:opsz,wght,SOFT,WONK@9..144,100..900,0..100,0..1&family=Fraunces:ital,opsz,wght,SOFT,WONK@1,9..144,100..900,0..100,0..1&display=swap");

/* Variables and page setup */

#help-content {
	font-family: Fraunces, serif;
	font-size: 115%;
	line-height: 1.35;
	font-weight: 340;
	color: var(--text);
	background: var(--background);
	padding-bottom: 20vh;

	/* Typetura */
	--tt-key: html;
	--tt-max: 900;
	--tt-ease: cubic-bezier(0, 0.78, 0.53, 1);

	/* Grid */
	--max-width: 1200px;
	--columns: 6;
	--gutter: 1.5rem;

	/* Color */
	--primary: #f4663a;
	--background: #efeded;
	--background-alt: #ffffff;
	--text: #31355b;
}
@keyframes html {
	0% {
		font-size: 0%;
	}

	100% {
		font-size: 125%;
	}
}
* {
	--grid: minmax(var(--gutter), 1fr)
		repeat(
			var(--columns),
			minmax(
				0,
				calc(
					(var(--max-width) - (var(--gutter) * (var(--columns) - 1))) /
						var(--columns)
				)
			)
		)
		minmax(var(--gutter), 1fr);
}

/* Element styles */

body {
	margin: 0;
}

img {
	width: 100%;
}

/* Typography */

.masthead {
	color: var(--primary);
	mix-blend-mode: multiply;
	text-align: center;
	font-variation-settings: "WONK" 1, "SOFT" 30;
	--tt-key: masthead;
	--tt-max: 1200;
	--tt-ease: ease-in-out;
	margin: 0.5em 0 -0.5em;
}
@keyframes masthead {
	0% {
		font-size: 1rem;
		margin-bottom: -0.45em;
		font-weight: 600;
	}
	100% {
		font-size: 6.5rem;
		margin-bottom: -0.375em;
		font-weight: 500;
	}
}

.primary-headline {
	--tt-key: primary-headline;
	--tt-ease: cubic-bezier(1, 0, 1, 1);
	--tt-max: 585;
	line-height: 1;
	font-variation-settings: "SOFT" 0, "WONK" 1;
	margin: 0.5rem 0;
}
@keyframes primary-headline {
	0% {
		font-size: 1rem;
		font-weight: 500;
	}
	100% {
		font-size: 4rem;
		font-weight: 300;
	}
}

.meta {
	font-style: italic;
	margin: 0.25rem 0;
	font-size: 0.75rem;
}

.entry-summary {
	margin: 0.25rem 0;
}

/* Layout */

.main {
	column-gap: var(--gutter);
	grid-template-columns: var(--grid);
	padding: var(--gutter);
}

.entry {
	margin-bottom: var(--gutter);
}

.entry,
.entry-content {
	display: flex;
	flex-direction: column;
}

.entry-img {
	aspect-ratio: 5 / 3;
	object-fit: cover;
}

.entry-date {
	order: -1;
}

/* Module overrides */

.entry-lede time {
	margin-bottom: 1rem;
}

.entry-lede time::after {
	content: "";
	display: block;
	width: calc(100% + (var(--gutter) * 2));
	height: 1px;
	margin-left: calc(var(--gutter) * -2);
	background: var(--primary);
	mix-blend-mode: multiply;
	margin-top: 0.67rem;
}

/* Breakpoints */

@media (min-width: 30em) {
	#help-content {
		--columns: 12;
	}
	.main,
	.entry-lede {
		padding: 0;
		display: grid;
	}

	.entry,
	.trending {
		grid-column-start: 3;
		grid-column-end: span 10;
	}

	.entry-lede {
		column-gap: var(--gutter);
		grid-template-columns: var(--grid);
		grid-column-start: 1;
		grid-column-end: span 14;
		margin-bottom: 4rem;
	}

	.entry-lede > .entry-img {
		aspect-ratio: auto;
		grid-column-start: 1;
		grid-column-end: span 10;
	}

	.entry-lede .entry-content {
		grid-column-start: 3;
		grid-column-end: span 11;
		margin: -2.5rem calc(var(--gutter) * -1) 0;
		background: var(--background-alt);
		padding: 0.5rem var(--gutter) 1rem;
	}
}
@media (min-width: 50em) {
	#help-content {
		--columns: 24;
	}

	.main::before,
	.main::after {
		content: "";
		display: block;
		grid-column-start: 8;
		grid-column-end: 26;
		grid-row-start: 2;
		grid-row-end: 3;
		background: var(--background-alt);
		margin: 0 calc(var(--gutter) * -1);
		z-index: -1;
	}
	.main::after {
		grid-column-start: 2;
		background-color: var(--primary);
		height: 1px;
		mix-blend-mode: multiply;
	}

	.entry {
		grid-column-end: span 9;
		margin-bottom: var(--gutter);
	}
	.entry:nth-child(n + 2) {
		margin-top: -2rem;
		grid-row-start: 2;
	}
	.entry:nth-child(2) {
		grid-column-start: 8;
	}
	.entry:nth-child(3) {
		grid-column-start: 17;
	}

	.entry-lede {
		column-gap: var(--gutter);
		grid-template-columns: var(--grid);
		grid-column-start: 1;
		grid-column-end: span 26;
		margin-bottom: 4rem;
	}

	.entry-lede > .entry-img {
		aspect-ratio: auto;
		grid-column-start: 1;
		grid-column-end: span 13;
	}

	.entry-lede .entry-content {
		grid-column-start: 14;
		grid-column-end: span 12;
		margin: 0.5rem 0;
		padding: 4rem 0 0;
		background: none;
	}

	.trending {
		grid-row-start: 2;
		grid-column-start: 2;
		grid-column-end: span 6;
		margin-top: var(--gutter);
		transform: translatex(calc(var(--gutter) * -1));
	}
}

.entry-lede .entry-headline {
  font-size: 4.5em;
  font-weight: 300;
}

.header-title.masthead {
  font-size: 5.5em;
}

</style>
<div class="container-fluid" id="help-content">
<header class="header">
	<h1 class="header-title masthead">Equestrian</h1>
</header>
<main class="main">
	<article class="entry entry-lede">
		<img class="entry-img" src="https://assets.codepen.io/467/horse02.jpg" alt="A handsome young horse in front of an expansive sky" />
		<div class="entry-content">
			<h1 class="entry-headline primary-headline">This horse sure knows how to code</h1>
			<time class="entry-date meta">January 24, 2021</time>
			<span class="entry-byline meta">by Alex Trost</span>
			<p class="entry-summary">Aliquam justo enim, mollis a justo et, sagittis vulputate turpis. Nulla facilisi. Proin quis mattis ipsum, eu eleifend dolor. Nulla auctor ex vel ipsum varius viverra. Vestibulum convallis elit nec quam bibendum varius. Morbi ut mattis dui. Aenean a massa vitae magna commodo consequat. Duis ac lacus.</p>
		</div>
	</article>
	<article class="entry">
		<img class="entry-img" src="https://assets.codepen.io/467/horse03.jpg" alt="The profile view of three majestic brown horses" />
		<h1 class="entry-headline primary-headline">Trost’s tolt trots to TypeScript</h1>
		<time class="entry-date meta">January 24, 2021</time>
		<span class="entry-byline meta">by Alex Trost</span>
		<p class="entry-summary">Cras mollis dolor vitae tellus sollicitudin, quis sagittis mauris dictum. Donec aliquet ipsum et ex pulvinar, id vestibulum lectus egestas. Vestibulum non ultrices mauris, nec elementum mauris. Donec aliquet ipsum at risus vulputate viverra.</p>
	</article>
	<article class="entry">
		<img class="entry-img" src="https://assets.codepen.io/467/horse01.jpg" alt="The profile view of three majestic brown horses" />
		<h1 class="entry-headline primary-headline">This team of three does front end magic</h1>
		<time class="entry-date meta">January 24, 2021</time>
		<span class="entry-byline meta">by Alex Trost</span>
		<p class="entry-summary">Mauris ut volutpat quam. Duis vitae turpis volutpat dolor efficitur rhoncus. Aenean lacinia est non porta dictum. Curabitur cursus mauris est, nec pharetra nisi imperdiet eget. Suspendisse non ultricies ligula.</p>
	</article>
	<section class="trending">
		<article class="trending-entry">
			<time class="trending-entry-date meta">January 24, 2021</time>
			<h1 class="trending-entry-headline primary-headline">Gallop into the amazing new world of CSS</h1>
		</article>
		<article class="trending-entry">
			<time class="trending-entry-date meta">January 24, 2021</time>
			<h1 class="trending-entry-headline primary-headline">Horse around with new HTML5 tags</h1>
		</article>
		<article class="trending-entry">
			<time class="trending-entry-date meta">January 24, 2021</time>
			<h1 class="trending-entry-headline primary-headline">Five featured fonts of front end horse</h1>
		</article>
		<article class="trending-entry">
			<time class="trending-entry-date meta">January 24, 2021</time>
			<h1 class="trending-entry-headline primary-headline">Saddle up with Rust</h1>
		</article>
	</section>
</main>
</div>
`;

export const add_items = `
<link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">
<!-- Header -->
<header class="w3-display-container w3-content w3-wide" style="max-width:1500px;" id="home">
  <img class="w3-image" src="https://www.w3schools.com/w3images/architect.jpg" alt="Architecture" width="1500" height="800">
  <div class="w3-display-middle w3-margin-top w3-center">
    <h1 class="w3-xxlarge w3-text-white"><span class="w3-padding w3-black w3-opacity-min"><b>BR</b></span> <span class="w3-hide-small w3-text-light-grey">Architects</span></h1>
  </div>
</header>

<div class="w3-content w3-padding" style="max-width:1564px">

  <!-- Project Section -->
  <div class="w3-container w3-padding-32" id="projects">
    <h3 class="w3-border-bottom w3-border-light-grey w3-padding-16">Projects</h3>
  </div>

  <div class="w3-row-padding">
    <div class="w3-col l3 m6 w3-margin-bottom">
      <div class="w3-display-container">
        <div class="w3-display-topleft w3-black w3-padding">Summer House</div>
        <img src="https://www.w3schools.com/w3images/house5.jpg" alt="House" style="width:100%">
      </div>
    </div>
    <div class="w3-col l3 m6 w3-margin-bottom">
      <div class="w3-display-container">
        <div class="w3-display-topleft w3-black w3-padding">Brick House</div>
        <img src="https://www.w3schools.com/w3images/house2.jpg" alt="House" style="width:100%">
      </div>
    </div>
    <div class="w3-col l3 m6 w3-margin-bottom">
      <div class="w3-display-container">
        <div class="w3-display-topleft w3-black w3-padding">Renovated</div>
        <img src="https://www.w3schools.com/w3images/house3.jpg" alt="House" style="width:100%">
      </div>
    </div>
    <div class="w3-col l3 m6 w3-margin-bottom">
      <div class="w3-display-container">
        <div class="w3-display-topleft w3-black w3-padding">Barn House</div>
        <img src="https://www.w3schools.com/w3images/house4.jpg" alt="House" style="width:100%">
      </div>
    </div>
  </div>

  <div class="w3-row-padding">
    <div class="w3-col l3 m6 w3-margin-bottom">
      <div class="w3-display-container">
        <div class="w3-display-topleft w3-black w3-padding">Summer House</div>
        <img src="https://www.w3schools.com/w3images/house2.jpg" alt="House" style="width:99%">
      </div>
    </div>
    <div class="w3-col l3 m6 w3-margin-bottom">
      <div class="w3-display-container">
        <div class="w3-display-topleft w3-black w3-padding">Brick House</div>
        <img src="https://www.w3schools.com/w3images/house5.jpg" alt="House" style="width:99%">
      </div>
    </div>
    <div class="w3-col l3 m6 w3-margin-bottom">
      <div class="w3-display-container">
        <div class="w3-display-topleft w3-black w3-padding">Renovated</div>
        <img src="https://www.w3schools.com/w3images/house4.jpg" alt="House" style="width:99%">
      </div>
    </div>
    <div class="w3-col l3 m6 w3-margin-bottom">
      <div class="w3-display-container">
        <div class="w3-display-topleft w3-black w3-padding">Barn House</div>
        <img src="https://www.w3schools.com/w3images/house3.jpg" alt="House" style="width:99%">
      </div>
    </div>
  </div>

  <!-- About Section -->
  <div class="w3-container w3-padding-32" id="about">
    <h3 class="w3-border-bottom w3-border-light-grey w3-padding-16">About</h3>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Excepteur sint
      occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
      laboris nisi ut aliquip ex ea commodo consequat.
    </p>
  </div>

  <div class="w3-row-padding w3-grayscale">
    <div class="w3-col l3 m6 w3-margin-bottom">
      <img src="https://www.w3schools.com/w3images/team2.jpg" alt="John" style="width:100%">
      <h3>John Doe</h3>
      <p class="w3-opacity">CEO & Founder</p>
      <p>Phasellus eget enim eu lectus faucibus vestibulum. Suspendisse sodales pellentesque elementum.</p>
      <p><button class="w3-button w3-light-grey w3-block">Contact</button></p>
    </div>
    <div class="w3-col l3 m6 w3-margin-bottom">
      <img src="https://www.w3schools.com/w3images/team1.jpg" alt="Jane" style="width:100%">
      <h3>Jane Doe</h3>
      <p class="w3-opacity">Architect</p>
      <p>Phasellus eget enim eu lectus faucibus vestibulum. Suspendisse sodales pellentesque elementum.</p>
      <p><button class="w3-button w3-light-grey w3-block">Contact</button></p>
    </div>
    <div class="w3-col l3 m6 w3-margin-bottom">
      <img src="https://www.w3schools.com/w3images/team3.jpg" alt="Mike" style="width:100%">
      <h3>Mike Ross</h3>
      <p class="w3-opacity">Architect</p>
      <p>Phasellus eget enim eu lectus faucibus vestibulum. Suspendisse sodales pellentesque elementum.</p>
      <p><button class="w3-button w3-light-grey w3-block">Contact</button></p>
    </div>
    <div class="w3-col l3 m6 w3-margin-bottom">
      <img src="https://www.w3schools.com/w3images/team4.jpg" alt="Dan" style="width:100%">
      <h3>Dan Star</h3>
      <p class="w3-opacity">Architect</p>
      <p>Phasellus eget enim eu lectus faucibus vestibulum. Suspendisse sodales pellentesque elementum.</p>
      <p><button class="w3-button w3-light-grey w3-block">Contact</button></p>
    </div>
  </div>

  <!-- Contact Section -->
  <div class="w3-container w3-padding-32" id="contact">
    <h3 class="w3-border-bottom w3-border-light-grey w3-padding-16">Contact</h3>
    <p>Lets get in touch and talk about your next project.</p>
    <form action="/action_page.php" target="_blank">
      <input class="w3-input w3-border" type="text" placeholder="Name" required name="Name">
      <input class="w3-input w3-section w3-border" type="text" placeholder="Email" required name="Email">
      <input class="w3-input w3-section w3-border" type="text" placeholder="Subject" required name="Subject">
      <input class="w3-input w3-section w3-border" type="text" placeholder="Comment" required name="Comment">
      <button class="w3-button w3-black w3-section" type="submit">
        <i class="fa fa-paper-plane"></i> SEND MESSAGE
      </button>
    </form>
  </div>

<!-- Image of location/map -->
<div class="w3-container">
  <img src="https://www.w3schools.com/w3images/map.jpg" class="w3-image" style="width:100%">
</div>

<!-- End page content -->
</div>

`;
