/* global MatgenGlobal, TENANT_ENV, API_ENV */
import { Amplify as AWSAmplify, Auth, Storage, API } from 'aws-amplify';

class M4CAmplify {
  constructor({ AMPLIFY_VARS, cb } = {}) {
    if (!MatgenGlobal) {
      throw new Error('Amplify module: MatgenGlobal not initialized.');
    }
    if (!TENANT_ENV) {
      throw new Error('Amplify module: TENANT_ENV not initialized.');
    }
    if (!API_ENV) {
      throw new Error('Amplify module: API_ENV not initialized.');
    }
    this.Amplify = AWSAmplify;
    this.Auth = Auth;
    this.Storage = Storage;
    this.API = API;

    if (!MatgenGlobal.Amplify) {
      MatgenGlobal.Amplify = {
        Auth,
        Storage,
        API,
      };
    }

    this.configure(AMPLIFY_VARS).then(() => {
      if (cb && typeof cb === 'function') {
        cb();
      }
    });
  }

  async getAuthenticatedUser() {
    try {
      const user = await MatgenGlobal.Amplify.Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      return user;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async configure(AMPLIFY_VARS) {
    MatgenGlobal.AmplifyConfig = await this.getAmplifyConfig(AMPLIFY_VARS);
    if (!MatgenGlobal.AmplifyConfig) {
      throw new Error('Amplify module: Amplify configuration build failed.');
    }
    MatgenGlobal.MatgenAmplify.Amplify.configure(MatgenGlobal.AmplifyConfig);

    console.log('API_ENV:', API_ENV, 'TENANT_ENV:', TENANT_ENV);
    console.log('AmplifyConfig', MatgenGlobal.AmplifyConfig);
  }

  getAmplifyConfig(AMPLIFY_VARS) {
    return {
      Auth: {
        mandatorySignIn: AMPLIFY_VARS.MANDATORY_SIGNIN,
        region: AMPLIFY_VARS.region,
        userPoolId: AMPLIFY_VARS.userPoolId,
        identityPoolId: AMPLIFY_VARS.identityPoolId,
        userPoolWebClientId: AMPLIFY_VARS.userPoolWebClientId,

        oauth: {
          domain: AMPLIFY_VARS.domain,
          scope: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: AMPLIFY_VARS.redirectSignIn,
          redirectSignOut: AMPLIFY_VARS.redirectSignOut,
          clientId: AMPLIFY_VARS.clientId,
          responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },

      Storage: {
        region: AMPLIFY_VARS.region,
        bucket: AMPLIFY_VARS.s3Bucket,
        userPoolId: AMPLIFY_VARS.userPoolId,
        identityPoolId: AMPLIFY_VARS.identityPoolId,
      },

      API: {
        endpoints: [
          {
            name: 'devauth',
            endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}/devauth`,
            region: AMPLIFY_VARS.region,
          },
          {
            name: 'authenticated',
            endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}/authenticated`,
            region: AMPLIFY_VARS.region,
          },
          {
            name: 'public',
            endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}/public`,
            region: AMPLIFY_VARS.region,
          },
          {
            name: 'mailer',
            endpoint: `https://${AMPLIFY_VARS.apiId}.execute-api.us-east-1.amazonaws.com/${AMPLIFY_VARS.apiStage}`,
            region: AMPLIFY_VARS.region,
          },
        ],
      },
    };
  }
}

export default M4CAmplify;
