/* global $, MatgenGlobal */

import { fabric } from 'fabric';

class DrawingCanvas {
  constructor(canvasContainerId, opts) {
    this.opts = opts;
    this.min = 99;
    this.max = 999999;
    this.reset();

    $(`#${canvasContainerId}`).prepend(
      `
        <div id="drawing-canvas-container" class="matgen-drawing" style="border:3px dashed #ff0000;">
          <canvas id="drawing-canvas"></canvas>
        </div>
      `
    );

    this.canvas = new fabric.Canvas('drawing-canvas');
    this.canvas.setWidth(MatgenGlobal.editor.templateWidth);
    this.canvas.setHeight(MatgenGlobal.editor.templateHeight);
    this.canvas.setZoom(MatgenGlobal.editor.cur().fabric.getZoom());

    this.canvas.on('mouse:down', options => {
      if (options.target && options.target.id == this.pointArray[0].id) {
        this.generatePolygon(this.pointArray);
      }
      if (this.polygonMode) {
        this.addPoint(options);
      } else {
        this.reset();
      }
    });

    this.canvas.on('mouse:move', options => {
      if (this.activeLine && this.activeLine.class == 'line') {
        const pointer = this.canvas.getPointer(options.e);
        this.activeLine.set({ x2: pointer.x, y2: pointer.y });

        const points = this.activeShape.get('points');
        points[this.pointArray.length] = {
          x: pointer.x,
          y: pointer.y,
        };
        this.activeShape.set({
          points: points,
        });
        this.canvas.renderAll();
      }
      this.canvas.renderAll();
    });
  }

  addPoint(options) {
    const random =
      Math.floor(Math.random() * (this.max - this.min + 1)) + this.min;
    const id = new Date().getTime() + random;
    const circle = new fabric.Circle({
      radius: 5,
      fill: '#ffffff',
      stroke: '#333333',
      strokeWidth: 0.5,
      left: options.e.layerX / this.canvas.getZoom(),
      top: options.e.layerY / this.canvas.getZoom(),
      selectable: false,
      hasBorders: false,
      hasControls: false,
      originX: 'center',
      originY: 'center',
      id: id,
      objectCaching: false,
    });
    if (this.pointArray.length == 0) {
      circle.set({
        fill: 'red',
      });
    }
    let points = [
      options.e.layerX / this.canvas.getZoom(),
      options.e.layerY / this.canvas.getZoom(),
      options.e.layerX / this.canvas.getZoom(),
      options.e.layerY / this.canvas.getZoom(),
    ];
    const line = new fabric.Line(points, {
      strokeWidth: 2,
      fill: '#999999',
      stroke: '#999999',
      class: 'line',
      originX: 'center',
      originY: 'center',
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
    });
    if (this.activeShape) {
      const pos = this.canvas.getPointer(options.e);
      points = this.activeShape.get('points');
      points.push({
        x: pos.x,
        y: pos.y,
      });
      const polygon = new fabric.Polygon(points, {
        stroke: '#333333',
        strokeWidth: 1,
        fill: '#cccccc',
        opacity: 0.3,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
      });
      this.canvas.remove(this.activeShape);
      this.canvas.add(polygon);
      this.activeShape = polygon;
      this.canvas.renderAll();
    } else {
      const polyPoint = [
        {
          x: options.e.layerX / this.canvas.getZoom(),
          y: options.e.layerY / this.canvas.getZoom(),
        },
      ];
      const polygon = new fabric.Polygon(polyPoint, {
        stroke: '#333333',
        strokeWidth: 1,
        fill: '#cccccc',
        opacity: 0.3,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false,
      });
      this.activeShape = polygon;
      this.canvas.add(polygon);
    }
    this.activeLine = line;

    this.pointArray.push(circle);
    this.lineArray.push(line);

    this.canvas.add(line);
    this.canvas.add(circle);
    this.canvas.selection = false;
  }

  reset() {
    this.polygonMode = true;
    this.pointArray = new Array();
    this.lineArray = new Array();
    this.activeLine = false;
    this.activeShape = false;
  }

  generatePolygon() {
    const points = new Array();
    $.each(this.pointArray, function(index, point) {
      points.push({
        x: point.left,
        y: point.top,
      });
      this.canvas.remove(point);
    });
    $.each(this.lineArray, function(index, line) {
      this.canvas.remove(line);
    });
    this.canvas.remove(this.activeShape).remove(this.activeLine);
    const polygon = new fabric.Polygon(points, {
      stroke: this.opts.stroke,
      strokeWidth: this.opts.strokeWidth,
      fill: this.opts.fill,
      //opacity: 1,
      //hasBorders: false,
      //hasControls: false,
    });
    this.canvas.add(polygon);

    this.activeLine = null;
    this.activeShape = null;
    this.polygonMode = false;
    this.canvas.selection = true;
  }
}

export { DrawingCanvas };
