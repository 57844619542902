import { Input } from './Input.js';

class CheckboxInput extends Input {
  constructor({ isControl = true }) {
    super(...arguments);
    this.isControl = isControl;
  }
  getHTML() {
    return `
    <div class="row mt-2">
      <div class="col-5 col-form-label"></div>
      <div class="col-7">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" name="${
            this.id
          }" id="${this.id}" ${this.value ? 'checked' : ''}>
          <label class="form-check-label" for="${this.id}">
            ${this.label}
          </label>
        </div>
      </div>
    </div>
    `;
  }
}

export { CheckboxInput };
