import { Input } from './Input.js';

class TextAreaInput extends Input {
  constructor() {
    super(...arguments);
  }
  getHTML() {
    return `
      <div class="row">
        <label for="${this.id}" class="col-5 col-form-label">${
      this.label
    }</label>
      <div class="col-7">
        <textarea
          type="${this.type}"
          id="${this.id}"
          name="${this.id}"
          class="form-control ${this.classes}"
          placeholder="${this.label}"
          ${this.required ? 'required' : ''}
          ${this.autofocus ? 'autofocus' : ''}
          ${this.value !== false ? `value="${this.value}"` : ''}
        >${this.value ? this.value : ''}</textarea>
      </div>
    </div>
    `;
  }
}

export { TextAreaInput };
