import {
  Form,
  TextInput,
  TextAreaInput,
  //CheckboxInput,
} from '../form/index.js';
//import { emit } from '../../matgen-ui/common/helpers.js';

const SectionAnswerInputs = [
  new TextAreaInput({
    label: 'Answer Text',
    id: 'inputText',
    classes: 'top',
  }),
  new TextInput({
    type: 'subtext',
    label: 'Subtext',
    id: 'inputSubtext',
    classes: 'middle',
  }),
  `
  <div id="icon-preview">
    <div id="answer-icon"><i class="fa-duotone fa-circle-question fa-2xl"></i></div>
    <button id="toggle-icon-chooser" type="button" class="btn btn-secondary">Choose Icon</button>
  </div>
  `,
  new TextInput({
    type: 'number',
    label: 'Sort order',
    id: 'inputSortOrder',
    classes: 'bottom',
  }),
];

const SectionAnswerForm = new Form({
  inputs: SectionAnswerInputs,
  id: 'answer-form',
  title: 'Create/Edit Answer',
});

export { SectionAnswerForm };
