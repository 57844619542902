/* global MatgenGlobal, API_ENV, TENANT_ENV */

import M4CAmplify from '../../m4c-amplify/M4CAmplify.js';

//import { init as eventsInit } from './implementation/events/canvas-events.js';
//import { loadMenus } from './matgen-ui/components/intra-menu.js';

import './events/template-editor-events.js';

MatgenGlobal.Options = {
  env: API_ENV === 'production' ? 'prod' : 'dev',
  tenant_env: TENANT_ENV ? TENANT_ENV : 'm4c',
  containerId: 'matgen-scale-container-default',
  //editorTargetSelector: '#matgen-controller',
  scalingTargetId: 'matgen-scale-container-default-scale',
  sidebarTargetSelector: '#matgen-sidebar',
  rootPage: 'index.html',
};

MatgenGlobal.DEBUG_LOADER = API_ENV === 'production' ? false : true;
MatgenGlobal.DEBUG_LOADER = true;
MatgenGlobal.SidebarId = 'sidebar';
MatgenGlobal.LoginPage = '/login';

(async () => {
  const api_env = API_ENV === 'production' ? 'prod' : 'dev';
  const tenant_env = TENANT_ENV ? TENANT_ENV : 'm4c';
  const tenantEnvironment = await import(
    `../../.config/tenants/${TENANT_ENV}.js`
  );
  const AMPLIFY_VARS = tenantEnvironment.default[tenant_env][api_env];

  if (!AMPLIFY_VARS) {
    throw new Error('Amplify module: Missing configuration.');
  }

  MatgenGlobal.AMPLIFY_VARS = AMPLIFY_VARS;
  if (!MatgenGlobal.base_config) {
    MatgenGlobal.base_config = AMPLIFY_VARS;
  }

  MatgenGlobal.MatgenAmplify = new M4CAmplify({
    AMPLIFY_VARS,
    //cb: () => {},
  });

  MatgenGlobal.init(MatgenGlobal.Options);
})();
